(function ($) {
    $('#video-modal-mediaelement').on('show.bs.modal', function (event) {
        if ($('.js-mejs-reset-on-hide').length <= 1) {
            $('.js-mejs-reset-on-hide').mejsResetOnHide();
        }
        const component = $(event.relatedTarget);
        $('#video-mediaelement').attr('poster', component.attr('data-imagem'));
        $('#video-mediaelement_html5').attr('poster', component.attr('data-imagem'));
        if(component.attr('data-video-url')) {
            $('#video-mediaelement_html5').attr('src', component.attr('data-video-url'));
        } else {
            $('#video-mediaelement_html5').attr('src', component.attr('href'));
        }  
        $('#video-modal-mediaelement .mejs__poster').attr('style', 'background-image: url("' + component.attr('data-imagem') + '"); width: 100%; height: 100%;');
        $('#video-modal-mediaelement .mejs__poster-img').attr('src', component.attr('data-imagem'));

        var video = component.attr('data-video-url');
        if (video.indexOf('youtube') > -1) {
            $('#video-iframe').attr('src', `${video}?rel=0&modestbranding=1&autohide=1&autoplay=1&controls=0&showinfo=0`).css('display', 'block');
            $('#video-mediaelement').attr('src', '').css('display', 'none');
            $('.mejs__container').css('display', 'none');
        } else {
            $('#video-mediaelement').css('display', 'block');
            $('#sourceModalVideo').attr('src', video);
            $('#video-iframe').attr('src', '');
            $('.mejs__container').css('display', 'block');
        }
        $('#downloadModalVideo').attr('href', component.attr('data-video-url'));
        $('#video-modal-mediaelement-label').html(component.attr('data-video-titulo'));
        $('#video-mediaelement-modal-body').attr('style', 'display: block');
    });

})(jQuery);
