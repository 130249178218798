/* eslint-disable no-undef */
/* global modernizr */
/* global $A */
/**
 * datepicker
 * Converte um input type='text' em um datepicker.
 * Dependendo do suporte/ambiente definido pelo navegador:
 * - Usa o controle nativo (input type='date'); OU
 * - Usa um widget criado por  javascript
 * 
 * Opções.
 * Esse plugin recebe um objeto em seu construtor com as opções.
 * Ex:
 * $('.js-datepicker').setDatepicker({
 *      format: 'iso'
 * });
 * 
 * Opções disponíveis:
 * - format
 *   format pode receber os seguintes valores:
 *   - 'pt-br': a data será informada no formato dd/mm/yyyy
 *   - 'iso': a data será informada no formato yyyy-mm-dd
 *   - função: uma função que recebe a data no formato pt-br (dd/mm/yyyy) e retorna a data formatada. O valor retornado será o valor contido no campo
 * - maxDate
 *   recebe uma string que determina qual será o primeiro dia disponível para seleção no calendário disponibilizado pelo widget
 *   ...obedecendo as regras do plugin original, aceita um objeto Date ou strings que correspondam a um offset desde a data atual, como inteiro,
 *   ...positivo ou negativo, que significa a quantidade de dias antes ou depois da data corrente.
 *   ... ex: '-1', '+3', '+4'
 * - minDate
 *   recebe uma string que determina qual será o primeiro dia disponível para seleção no calendário disponibilizado pelo widget
 *   ...obedecendo as regras do plugin original, aceita um objeto Date ou strings que correspondam a um offset desde a data atual, como inteiro,
 *   ...positivo ou negativo, que significa a quantidade de dias antes ou depois da data corrente.
 *   ... ex: '-1', '+3', '+4'
 */
(function ($) {
    'use scrict';

    // Configuração padrão
    var defaults = {
        format: 'pt-br' // Entrega datas no formato pt-br
    };

    // Define se deve ser utilizado o controle nativo
    function useNative () {

        var native;

        /* Testa se:
            - navegador suporta input do tipo date; E
            - userAgent casa com um dos listados na expressão regular
        */
        native =
            modernizr.inputtypes.date &&
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            );

        return native;
    }

    /* Converte um string no formato "xx/yy/zzzz" para "zzzz-yy-xx"
     * Note que, apesar de a intenção ser trabalhar com datas, não há qualquer
     * validação em relação a datas (apenas muda as posições dos dígidos na string)
     */
    function ptBrToIso (date) {

        function replacer(match, p1, p2, p3) {
            return [p3, p2, p1].join('-');
        }

        return date.replace(/(\d{2})\/(\d{2})\/(\d{4})/, replacer);
    }

    /* Converte um string no formato "zzzz-yy-xx" para "xx/yy/zzzz"
     * Note que, apesar de a intenção ser trabalhar com datas, não há qualquer
     * validação em relação a datas (apenas muda as posições dos dígidos na string)
     */
    function isoToPtBr (date) {

        function replacer(match, p1, p2, p3) {
            return [p3, p2, p1].join('/');
        }

        return date.replace(/(\d{4})-(\d{2})-(\d{2})/, replacer);
    }

    /* Normaliza a apresentação  de datas no formato pt-br
     * Parte de algumas possibilidades e entrega sempre dd/mm/yyyy
     */
    function maskPtBr (date) {

        var 
            /* Casa com ddmmyyyy
               - dd: 01 a 31
               - mm: 01 a 12
               - yyyy: 1000 a 9999
               Note que não há validação de data. Por exemplo, 31/02/2019 casa com a expressão regular.
            */
            ddmmyyyy = /^\s*(0[1-9]|[1-2][0-9]|3[0-1])(0[1-9]|1[0-2])([1-9]\d{3})\s*$/,
            /* Casa com d/m/yyyy
               - d: 1 a 9
               - m: 1 a 9
               - yyyy: 1000 a 9999
            */
            d_m_yyyy = /^\s*([1-9])\/([1-9])\/([1-9]\d{3})\s*$/,
            /* Casa com dd/m/yyyy
               - dd: 01 a 31
               - m: 1 a 9
               - yyyy: 1000 a 9999
            */
            dd_m_yyyy = /^\s*(0[1-9]|[1-2][0-9]|3[0-1])\/([1-9])\/([1-9]\d{3})\s*$/,
            /* Casa com d/mm/yyyy
               - d: 1 a 9
               - mm: 01 a 12
               - yyyy: 1000 a 9999
            */
            d_mm_yyyy = /^\s*([1-9])\/(0[1-9]|1[0-2])\/([1-9]\d{3})\s*$/;



        if (ddmmyyyy.test(date)) {
            // Transforma ddmmyyyy em dd/mm/yyyy
            date = date.replace(ddmmyyyy, '$1/$2/$3');
        }
        else if (d_m_yyyy.test(date)) {
            // Transforma d/m/yyyy em 0d/0m/yyyy
            date = date.replace(d_m_yyyy, '0$1/0$2/$3');
        }
        else if (dd_m_yyyy.test(date)) {
            // Transforma dd/m/yyyy em dd/0m/yyyy
            date = date.replace(dd_m_yyyy, '$1/0$2/$3');
        }
        else if (d_mm_yyyy.test(date)) {
            // Transforma d/mm/yyyy em 0d/mm/yyyy
            date = date.replace(d_mm_yyyy, '0$1/$2/$3');
        }

        return date;
    }

    /* Dados o formato e uma data (string) no formaso iso (yyyy-mm-dd),
     * transforma a data para o formato especificado.
     * O parâmetro `format` pode ser um string (valores enumerados abaixo)
     * ou uma função
     * - "pt-br": "dd/mm/yyyy"
     * - "iso": "yyyy-mm-dd" (sem modificações)
     * - function: recebe data no formato "dd/mm/yyyy" como entrada,
     *   e deve retornar a data no formato desejado.
     */
    function changeFormat (format, isoDate) {

        var formatedDate;

        if (typeof format === 'function') {
            // Se for uma função, usa a função
            formatedDate = format(isoToPtBr(isoDate));
        }
        else if (format === 'iso') {
            // Se for 'iso', não precisa fazer transformação, só transferir
            formatedDate = isoDate;
        }
        else {
            // Se for 'pt-br' (ou qualquer outra coisa, por padrão), faz a transformação adequada
            formatedDate = isoToPtBr(isoDate);
        }

        return formatedDate;
    }

    let getISODatePartOnly = (data) => {
        const MILISECONDS_IN_ONE_DAY = 86400000;
        if (data instanceof Date) {
            return data.toISOString().substr(0,10);
        }
        if (isNaN(parseInt(data))) {
            return null;
        }
        // Retorna o cálculo de datas, com base em inteiro passado
        return new Date((new Date().getTime() + (data * MILISECONDS_IN_ONE_DAY))).toISOString().substr(0,10);
    };
    function createCalendar (input, trigger, proxy, options) {
        var inputId = input.attr('id');

        $A.setCalendar(`${inputId}_${$A.genId()}`, trigger.get(0), proxy.get(0), false, undefined,
        {

            // Configure optional overrides

            // If not included, all of the below values are set by default

            // Set role name text for screen reader users
            // role: 'Calendar',

            // Short help text message that is automatically announced to screen reader users when the calendar first opens.
            helpTextShort: 'Pressione H para ajuda.',

            // Set screen reader text to automatically be announced when H is pressed.
            // This is also set within the data-helptext attribute in the top level div element of the calendar for CSS pseudo element referencing via attr(data-helptext) for sighted keyboard only users if desired.
            helpText: 'Pressione as setas para navegar por dia, PageUp e PageDown para navegar por mês, Alt+PageUp e Alt+PageDown para navegar por ano, ou Esc para cancelar.',

            // Set tooltip text
            tooltipTxt: 'Pressione Esc para cancelar',
            disabledTxt: 'Desabilitado',
            markedTxt: 'Selecionado',
            commentedTxt: 'Tem comentário',
            prevTxt: 'Anterior',
            nextTxt: 'Próximo',
            monthTxt: 'Mês',
            yearTxt: 'Ano',

            // Set month names
            months: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],

            // Set short and long weekday names
            days: [
                {
                    s: 'Dom',
                    l: 'Domingo'
                },
                {
                    s: 'Seg',
                    l: 'Segunda-feira'
                },
                {
                    s: 'Ter',
                    l: 'Terça-feira'
                },
                {
                    s: 'Qua',
                    l: 'Quarta-feira'
                },
                {
                    s: 'Qui',
                    l: 'Quinta-feira'
                },
                {
                    s: 'Sex',
                    l: 'Sexta-feira'
                },
                {
                    s: 'Sab',
                    l: 'Sábado'
                }
            ],

            // Switch the behaviour when the PageUp or PageDown keys are pressed to a "natural" behaviour
            // (PageUp goes to previous month, PageDown goes to next month)
            // pageUpDownNatural: false,

            // Append a "dayToday" CSS class to the current day cell element - this allows styling to be targeted to this specific element
            highlightToday: true,

            // Fill in the day cells outside of the current month so that the calendar grid is always filled with day cells
            drawFullCalendar: true,

            // Run custom functions at the end of the code within the following component functions.
            // Receives a single parameter "dc", which provides access to the Datepicker object.
            // runBefore: function (dc) {
            //     console.log('runBefore');
            //     console.log(dc);
            // },
            // runAfterClose: function (dc) {
            //     console.log('runAfterClose');
            //     console.log(dc);
            // },

            runAfterClose: function (dc) {
                input.val(changeFormat(options.format, ptBrToIso(proxy.val())));
            },

            // Override the character used on the month / year change buttons
            // leftButtonYearText: '<',
            // rightButtonYearText: '>',
            leftButtonMonthText: '<i class="fas fa-chevron-left"></i>',
            rightButtonMonthText: '<i class="fas fa-chevron-right"></i>',

            // Specify if the calendar should open when the input field receives focus.
            // If true, the Down arrow must be pressed to move focus from the input field into the calendar for manual traversal, and Escape will collapse the calendar.
            // openOnFocus: false,
            // openOnFocusHelpText: 'Press Down arrow to browse the calendar, or Escape to close.',

            // Display a Close button
            // showEscBtn: true,
            escBtnName: 'Fechar',
            // escBtnIcon: '×',

            // Set specific start / end boundaries of a date range. Can be Date objects (absolute boundaries), or positive/negative integers (relative boundaries).
            // If undefined, no date range will be enforced.
            minDate: options.minDate, 
            maxDate: options.maxDate, 
            // Using a token system, set a specific date string format to be used when setting the selected value into the calendar input box
            // 'YYYY': 4 digit year, 2019
            // 'MMMM': Full name of month, January, etc.
            // 'dddd': Full name of weekday, Monday, etc.
            // 'MM': 2 digit month, 01, etc.
            // 'DD': 2 digit day, 01, etc.
            // 'Do': getDateOrdinalSuffix, 1st, 2nd, 3rd.
            // 'M': 1 or 2 digit month, 1 through 12
            // 'D': 1 or 2 digit day, 1 through 31.
            inputDateFormat: 'DD/MM/YYYY',

            // Using a token system, set a specific date string format to be read out to screen reader users
            audibleDateFormat: 'dddd, D/MMMM/YYYY',

            // Allow a date that isn't today to be set as the initial date. If unset, this value is initialised to today's date
            // initialDate: new Date(),

            // Disable weekdays from selection
            // disableWeekdays: false,

            // Disable weekends from selection
            // disableWeekends: false,

            // Set positive or negative offset for differing column arrangements, or 0 for none
            // wdOffset: 0,

            // Set CSS positioning calculation for the calendar
            // Set to 0 to disable auto positioning
            // autoPosition: 9,
            autoPosition: 0,

            // Customize with positive or negative offsets
            // offsetTop: 0,
            // offsetLeft: 0,

            // Set class for the calendar container
            className: 'g-datepicker',

            // Set custom CSS styling for the calendar container when rendered
            // cssObj: {
            //     position: 'absolute',
            //     zIndex: 1
            // },

            // Choose a different insertion point in the DOM; must be a DOM node; defaults to the triggering element if not specified.
            // targetObj: null,
            targetObj: input.closest('.input-group').get(0),


            // Choose a different focus element in the DOM for CSS autoPositioning; may be a DOM node or CSS Selector; defaults to the triggering element if not specified.
            // posAnchor: '',


            // Reset date to the current calendar date every time the date picker opens
            // resetCurrent: false,

            // Configure the Comments tooltip pane
            // comments: {
            //     role: 'Comment',
            //     autoPosition: 1,
            //     offsetTop: 0,
            //     offsetLeft: 0,
            //     className: 'commentTooltip'
            // },

            // Configure the editor form pane
            // editor: {
            //     // Choose to show the form, defaults to false
            //     show: false,
            //     // Set the section name, and the Edit button text
            //     role: 'Edit',
            //     autoPosition: 6,
            //     offsetTop: 0,
            //     offsetLeft: 0,
            //     className: 'commentAdd',
            //     // Set the Save button text
            //     action1: 'Save'
            // },

            // Condense the year display by removing the year nav buttons. Requires the Calendar Module version 1.25 or greater.
            condenseYear: true,

            // Manually configure the calendar using AJAX or a customization script
            // ajax: function (dc, save) {
            //     // 'save' is true when closing the Editor, false otherwise for fetching content when the calendar is opened.

            //     // If save is false, execute load script

            //     if (!save) {
            //         // Optionally load custom values into the dc.range associative array.

            //         // And optionally prevent this script from running again
            //         // dc.stopAjax = true;

            //         // Then open the calendar after processing is finished
            //         dc.open();
            //     }

            //     else {
            //         // Otherwise do something with the newly saved values within the dc.range associative array.
            //     }
            // },
        });
    }   

    $.fn.refreshDatepicker = function (options) {

        options = $.extend(false, {}, defaults, options);

        return this.each(function () {

            var input = $(this),
                inputId = input.attr('id'),
                trigger = input.siblings('.input-group-append').children('.input-group-text'),
                triggerClone = trigger.clone(),
                proxy = $('#' + inputId + '__proxy'),
                usingNative = useNative();

            if (usingNative) {
                // Do nothing, for a while
                
                proxy.attr({'max':getISODatePartOnly(options.maxDate),'min':getISODatePartOnly(options.minDate)});
            } 
            else {
                var idObjetoAccDC = function() {
                    var AccDCregister = $A.reg;
                    var result;
                    for (key in AccDCregister) {
                        if (AccDCregister.hasOwnProperty(key) && key.includes(inputId + '_')) {
                            result[key] = this[key];
                        }
                    }
                    return result;
                };
                
                $A.destroy(idObjetoAccDC);
                trigger.hide().after(triggerClone);
                trigger.remove();
                createCalendar(input, triggerClone, proxy, options);
            }
        });
    };

    $.fn.setDatepicker = function (options) {

        options = $.extend(false, {}, defaults, options);

        return this.each(function () {

            var input = $(this),
                inputId = input.attr('id'),
                proxy,
                trigger,
                label,
                initialPtBr,
                initialIso,
                usingNative = useNative();

            initialPtBr = maskPtBr(input.val());
            initialIso = ptBrToIso(initialPtBr);

            Date.prototype.isDate = function (){
                return (this !== 'Invalid Date' && !isNaN(this)) ? true : false;
            };
                        // Identificando o label
            label = input.closest('form').find(`label[for=${input.attr('id')}]`);

            // Criando o proxy (input com id e name derivados do input original)
            proxy = $('<input type="' + (usingNative ? 'date' : 'text') + '">');
            proxy.attr('id', inputId + '__proxy');
            proxy.attr('name', input.attr('name') + '__proxy');
            // Copia classes do input para o proxy (exceto as iniciadas com js);
            proxy.addClass(input.attr('class').replace(/(^|\s)js-\S*/ig, ''));

            // Associa o label ao proxy criado (se ele tiver sido corretamente associado ao input text original)
            label.attr('for', label.attr('for') + '__proxy');

            // Esconde o input original
            input.hide();

            // Seta o valor do campo inicialmente
            input.val(changeFormat(options.format, initialIso));

            // Se for usar o controle nativo "date"
            if (usingNative) {

                proxy.insertBefore(input);

                // Ajusta o valor inicial do proxy
                proxy.val(initialIso);
                
                proxy.attr({'max':getISODatePartOnly(options.maxDate),'min':getISODatePartOnly(options.minDate)});
                
                // Seta o valor do campo sempre que houver mudança no proxy
                proxy.on('change', function() {
                    // Define o valor que será entregue
                    input.val(changeFormat(options.format, proxy.val()));
                });
            }
            else {
                // Criando estrutura do input-group (boostratp);
                input.wrap('<div class="input-group"></div>');

                proxy.insertBefore(input);

                // Ajusta o valor inicial do proxy
                proxy.val(initialPtBr);

                // Criando trigger
                trigger = $('<a>')
                            .attr('href', '#')
                            .attr('aria-label', `Mostrar seletor de data para "${label.text()}"`)
                            .addClass('input-group-text')
                            .html('<i class="far fa-calendar-alt"></i>'); // @TODO Melhorar isso

                trigger.insertAfter(proxy);

                trigger.wrap( $('<div>').addClass('input-group-append') );

                createCalendar(input, trigger, proxy, options);

                // Ajustes sempre que houver mudança no proxy
                proxy.on('change', function(e) {
                    // Aplica a máscara
                    proxy.val(maskPtBr(proxy.val()));
                    // Define o valor que será entregue
                    input.val(changeFormat(options.format, ptBrToIso(proxy.val())));
                });
                // Refresh sempre que a referência 'neverbefore' sofrer alteração
                if (options.neverBefore) {
                    let bindInput = $(`#${$(options.neverBefore).attr('id')}__proxy`);
                    bindInput.on('blur', (evt) => {
                        if ( (new Date(ptBrToIso(bindInput.val())).isDate()) ) {
                            let dataMinima = new Date(ptBrToIso(bindInput.val())+'T00:00:00');
                            console.log(dataMinima);
                            input.refreshDatepicker( Object.assign(options,{ minDate: dataMinima }) );
                        }
                    });
                }
            }
        });
    };

})(jQuery);

//$('.js-datepicker').setDatepicker();
//$('.js-datepicker').setDatepicker({minDate: new Date('2020-04-26 00:00:0000'), maxDate: new Date('2020-04-30 00:00:0000')});
$('.js-datepicker').setDatepicker();
