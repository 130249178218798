/**
 * timeline
 * Converte uma lista de links em uma timeline padrão do portal
 * Uso:
 *
 */
(function ($) {

    // Valores defaul para options
    var defaults = {
        startAt: 0,
        baseClass: 'js-timeline'
    };

    $.fn.timeline = function (options) {

        var baseClass;

        options = $.extend({}, defaults, options);
        baseClass = '.' + options.baseClass;

        return this.each(function () {

            var frame = $(this),
                slyelement,
                items = frame.find(baseClass + '__item');

            slyelement = {
                obj: {},
                el: frame,
                options: {
                    horizontal: true,
                    itemNav: 'forceCentered',
                    activateMiddle: true,
                    smart: true,
                    activateOn: 'click',
                    mouseDragging: true,
                    touchDragging: true,
                    releaseSwing: true,
                    speed: 400,
                    startAt: options.startAt, // Item que estará inicialmente selecionado, ativo, centralizado...
                    next: '.js-timeline__next', // Selector or DOM element for "forward movement" button.
                    prev: '.js-timeline__prev' // Selector or DOM element for "backward movement" button.
                }
            };

            var navigation = $(
                '<div class="linha-tempo__navegacao" role="presentation" aria-hidden="true">' +
                    '<button type="button" class="linha-tempo__anterior js-timeline__prev" aria-hidden="true"><span class="sr-only">Ano anterior</span></button>' +
                    '<button type="button" class="linha-tempo__proximo  js-timeline__next" aria-hidden="true"><span class="sr-only">Próximo ano</span></button>' +
                '</div>');

            // Cria um container auxiliar, que fica em volta do container original
            frame.wrap('<div class="linha-tempo-conteiner-total"></div>');
            // Insere a navegação ao fim desse container auxiliar.
            frame.parent().append(navigation);

            if ($(slyelement.el).length > 0) {
                slyelement.obj = new Sly($(slyelement.el), slyelement.options);
                slyelement.obj.init();
            }

            $(window).resize(function () {
                if ($(slyelement.el).length > 0) {
                    slyelement.obj.reload();
                }
            });

            // Quando o link dentro de um item receber o foco,
            // resolve a visibilidade do elemento (para quem navega por teclado)
            $(items).find('a').on('focus', function() {
                // Obtém o indice do item onde o link está contido.
                var index = $(this).closest(baseClass + '__item').index();
                slyelement.obj.toCenter(index);
            });

        });
    };

})(jQuery);

/**
 * timeline API
 * Oferece uma função para desabilitar/habilitar uma timeline para interação
 * Uso:
 *
 */
$.fn.enableTimeline = function() {
    var navegacao = this.next('.linha-tempo__navegacao');

    if (! this.hasClass('linha-tempo__container')) {
        console.log('Can only be applied on a timeline container!');
        return;
    }
    this.find('a[tabindex=-1]').removeAttr('tabindex');
    this.removeClass('is-disabled');

    navegacao = this.next('.linha-tempo__navegacao');
    if (navegacao.length > 0) {
        navegacao.find('button[disabled]')
            .removeAttr('disabled')
            .removeAttr('tabindex')
            .removeAttr('aria-hidden');
        navegacao.removeClass('is-disabled');
    }

};
$.fn.disableTimeline = function() {
    var navegacao = this.next('.linha-tempo__navegacao');

    if (! this.hasClass('linha-tempo__container')) {
        console.log('Can only be applied on a timeline container!');
        return;
    }

    this.find('a[href]').attr('tabindex','-1');
    this.addClass('is-disabled');
    navegacao = this.next('.linha-tempo__navegacao');

    if (navegacao.length > 0) {
        navegacao.find('button')
            .attr('disabled','disabled')
            .attr('tabindex','-1')
            .attr('aria-hidden','true');
        navegacao.addClass('is-disabled');
    }

};
$.fn.changeTimelineState = function(newState) {
        //clean the current selected state within the timeline
        this.find('.linha-tempo__item').removeClass('linha-tempo__item--selecionado');
        //change the actual selected item within the timeline
        newState.closest('.linha-tempo__item').addClass('linha-tempo__item--selecionado');

};