/**
 * slider
 * Converte uma lista em um slider padrão do Portal
 * Uso:
 *
 */
(function ($) {


    // Valores defaul para options
    var defaults = {
        startAt: 0,
        baseClass: 'js-slider',
        itemsPerView: {
            SM: 1,
            MD: 2,
            LG: 3,
            XL: 3
        }
    };

    /*
     * Distribui padding latgeral em pixels para o element, de acordo com o gap informado
     * Basicamente, coloca o mesmo padding dos dois lados se o gap for par,
     * ou deixa um pixel a mais à direita se o gap for ímpar.
     */
    function setElementInlinePadding (element, gap) {
        var intQuotient = Math.floor(gap / 2);

        // Se o gap for ímpar, o pixel a mais fica à direita
        element.css('padding-right', (intQuotient + (gap % 2)) + 'px' ) ;
        element.css('padding-left', intQuotient + 'px');
    }

    /*
     * Ajusta a largura do frame, para garantir que ela seja divisível pela quantidade de itens no slider.
     * Isso é feito indiretamente, ao ajustar o padding do elemento auxiliar auxiliarContainer.
     */
    function adjustFrame (frame, itemsPerView) {

        let gap;
        /* eslint-disable */
        const itemsPerCurrentView = 
            document.body.offsetWidth > RESPONSIVE.MIN.XL ? itemsPerView.XL
            : document.body.offsetWidth > RESPONSIVE.MIN.LG ? itemsPerView.LG
            : document.body.offsetWidth > RESPONSIVE.MIN.MD ? itemsPerView.MD
            : document.body.offsetWidth > RESPONSIVE.MIN.SM ? itemsPerView.SM : 1;

        const frameModifierClass = `standard-slider__frame--${itemsPerCurrentView}by${itemsPerCurrentView}`;
        const elFrameClassList = frame[0].classList;
        const currentModifier = [...elFrameClassList].filter( (item) => item.startsWith('standard-slider__frame--'))[0];
        elFrameClassList.remove(currentModifier);

        frame.addClass(frameModifierClass);
        /* eslint-enable */
        const auxiliarContainer = frame.parent();

        // As linhas abaixo tentam garantir que o frame tenha uma largura divisível por itemsPerView
        // (a quantidade de itens em telas LG) 
        // "Reset" no padding colocado dinamicamente
        //setElementInlinePadding(auxiliarContainer, 0);

        // Encontra os pixels que "sobraram"
        gap = frame.width() % itemsPerCurrentView;

        // Coloca os pixels que "sobraram" como padding da div auxiliar auxiliarContainer
        setElementInlinePadding(auxiliarContainer, gap);
    }

    $.fn.slider = function (options) {

        var baseClassSelector;

        options = $.extend(true, {}, defaults, options);
        baseClassSelector = '.' + options.baseClass;

        return this.each(function () {

            var widget = $(this),
                slyelement,
                frame = widget.find(baseClassSelector + '__frame'),
                group = widget.find(baseClassSelector + '__group'),
                items = widget.find(baseClassSelector + '__item'),
                auxiliarContainer = $('<div style="overflow:hidden"></div>'),
                sliderNavigation;

            // Coloca o frame dentro dessa div auxiliar - que definirá a largura do frame, 
            //  para garantir que ela seja divisível por options.itemsPerView.XL.
            frame.wrap(auxiliarContainer);
            auxiliarContainer = frame.parent();
            // Ajusta a largura do frame
            adjustFrame(frame, options.itemsPerView);

            sliderNavigation = $(
                '<div class="btn-group standard-slider__navigation js-slider__navigation" aria-hidden="true">' +
                    '<button type="button" class="btn standard-slider__anterior js-slider__prev-page" tabindex="-1"></button>' +
                    '<button type="button" class="btn standard-slider__proximo  js-slider__next-page" tabindex="-1"></button>' +
                '</div>');

            // Insere a navegação ao fim do container do widget.
            widget.append(sliderNavigation);

            slyelement = {
                obj: {},
                el: frame,
                options: {
                    horizontal: true,
                    itemNav: 'basic',
                    mouseDragging: true,
                    touchDragging: true,
                    releaseSwing: true,
                    speed: 400,
                    startAt: options.startAt, // Item que estará inicialmente selecionado, ativo, centralizado...
                    next: widget.find(baseClassSelector + '__next'),
                    prev: widget.find(baseClassSelector + '__prev'),
                    nextPage: widget.find(baseClassSelector + '__next-page'),
                    prevPage: widget.find(baseClassSelector + '__prev-page'),
                    slidee: group
                }
            };

            if ($(slyelement.el).length > 0) {
                slyelement.obj = new Sly($(slyelement.el), slyelement.options);
                slyelement.obj.init();
            }

            $(window).resize(function () {
                if ($(slyelement.el).length > 0) {
                    adjustFrame(frame, options.itemsPerView);
                    slyelement.obj.reload();
                }
            });

            // Quando o link dentro de um item receber o foco,
            // resolve a visibilidade do elemento (para quem navega por teclado)
            $(items).find('a').on('focus', function() {
                // Obtém o indice do item onde o link está contido.
                var index = $(this).closest(baseClassSelector + '__item').index();
                slyelement.obj.toCenter(index);
            });
        });
    };

})(jQuery);