$.fn.blockContent = function() {
    var hasLoader = this.find('.mask-loader').length;

    if (!hasLoader) {
        this.prepend('<div class="mask-loader"><div class="loader"></div></div>');
    }

    this.addClass('blocked-content');
};

$.fn.unblockContent = function () {
    this.find('.mask-loader').remove();
    this.removeClass('blocked-content');
};

$.fn.animateContent = function(animation) {
    var animationOptions = ['pulse', 'blink', 'shake', 'flash'];

    var el = this;

    if (animationOptions.indexOf(animation) == -1) return;

    var animationClass = animation + '-element';

    this.removeClass('pulse-element blink-element shake-element flash-element');
    // Timeout com 0 utilizado para realizar a ação de forma assíncrona
    setTimeout(() => {
        el.addClass(animationClass);
    }, 0);
};