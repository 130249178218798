/**
 * youtubeLinked
 * 
 * Converte um link para vídeo do Youtube em disparador e player embutidos na página
 * Marcação HTML:
 * - O link deve possuir um atributo HREF com um link para o youtube válido, que é verificado por uma expressão regular
 * - O código do vídeo não é verificado, gerando uma mensage erro do Youtube na hora de exibir o vídeo
 *
 * Inicialização:
 * opção 1) Todo link que tenha a classe 'js-youtube-video' será transformada automaticamente após a carga do documento, sem necessidade de instanciação no script da página
 * opção 2) Inicialize através de chamada no script da página para qualquer link youtube que desejar, chamando desta forma: $('<localizador-de-elemente>').conectarVideoYoutube(), 
 *      onde, <localizador-do-elemento> pode ser qualquer query string válido para o jQuery. Somente links para o youtube serão transformados.
 * 
 * Dependência externa: 
 * Depende da biblioteca servida pelo Youtube para instanciação de Frames de Vídeos [IFrame Player API](https://developers.google.com/youtube/iframe_api_reference),
 *      que é instanciada por este plugin e cria um objeto no escopo global (window), nomeado 'YT'
 *
 */
(function ($) {
    'use scrict';

    const estruturaQS = '.youtube-linked-structure';
    /*eslint-disable*/
    const reYoutubeLink = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    /*eslint-enable*/

    function checkRequirements (candidateElement) {
        var $candidate = $(candidateElement);
        // Precisa ser uma tag A com um link para o youtube válido
        if (candidateElement.tagName != 'A' ||
            ! $candidate.attr('href').match(reYoutubeLink)) {
            return false;
        }
        return true;
    }
    function createLookAndFeel(targetLink) {
        var $target = $(targetLink);
        // adiciona a classe modificadora da aparência
        $target.addClass('youtube-linked');
    }
    function loadYoutubeFrameAPI () {
        var tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/player_api';
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
    function createPlayerInstance(targetId, videoId) {
         new window.YT.Player(targetId, {
            height: '450',
            width: '800',
            events: {
                'onReady': function onPlayerReady(event) {
                    event.target.playVideo();
                }
            },
            videoId: videoId
          });
    }
    function createYoutubePlayer(targetId, videoId) {
        if (! window.YT ) {
            loadYoutubeFrameAPI();
        } else {
            createPlayerInstance(targetId, videoId);
        }
        // Replace the 'ytplayer' element with an <iframe> and
        // YouTube player after the API code downloads.
        window.onYouTubePlayerAPIReady = function () {
            createPlayerInstance(targetId, videoId);
        };
    }
    function setPlayerContext(activatedLink) {
        var $link = $(activatedLink);
        // extrair o código do vídeo
        var videoId = $link.attr('href').match(reYoutubeLink)[5];
        // determinar as dimensões
        // montar a estrutura de suporte
        var $linkedStructure = $link.wrap($(`<div class="${estruturaQS.replace('.','')}"></div>`)).parent();
        var $iframeContainer = $('<div class="embed-responsive embed-responsive-16by9"></div>').appendTo($linkedStructure);
        // chamar a API do Youtube
        createYoutubePlayer($(`<b id="ytplayer-${Math.random()}"></b>`).appendTo($iframeContainer).attr('id'), videoId);
        // apagar o link original
        $link.remove();
    }

    $.fn.conectarVideoYoutube = function (options) {

        var $elements = this;
        var defaultOptions = {};
        
        options = $.extend({}, defaultOptions, options);

        return $elements.each( function () {
            var element = this;
            if ( ! checkRequirements(element) ) {
                return false;
            }
            // Monta a aparência padrão, com a marca do Youtube e a resposta ao hover
            createLookAndFeel(element);
            // Prepara o link para ser substituído pelo vídeo
            $(element).on('click', function (event) {
                event.preventDefault();
    
                setPlayerContext(event.currentTarget);
            });
        });
    };
})(jQuery);

$('.js-youtube-video').conectarVideoYoutube();