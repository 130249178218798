$(document).ready(function () {

  // Verificação das páginas para remover o botão "comunicar erro"
  // Caso não esteja na lista pathsPermitidos, remover o botão
  var pathsPermitidos = [];
  var sitePath = $(location).attr('href');
  var pathPermitido = false;
  var isPlone = $('.user-feedback').hasClass('plone');
  sitePath = sitePath.split('/');

  // Varre o array de paths permitidos para saber se a url contem a string
  for (let x in pathsPermitidos) {
    if ($.inArray(pathsPermitidos[x], sitePath) != -1) {
      pathPermitido = true;
      break;
    }
    else {
      pathPermitido = false;
    }
  }
  if(!isPlone){
    if (!pathPermitido) {
      $('.user-feedback').remove();
    }
  }
});

(function ($) {

  $('#user-feedback-form').on('submit', (event) => {
    const blockableContentFeedback = $('.modal-user-feedback__content');
    const email = $('#feedbackEmail').val();
    const mensagem = $('#feedbackContent').val();
    event.preventDefault();
    if (isCamposValidos(email, mensagem)) {
      const urlAtual = window.location.href;
      /*eslint-disable*/
      const urlFeedback = $baseUrlTemaWebpack + '/feedback';
      /*eslint-enable*/
      blockableContentFeedback.blockContent();
      $.post(urlFeedback, { email: email, mensagem: mensagem, urlAtual: urlAtual }, function (data, textStatus, jqXHR) {
        blockableContentFeedback.unblockContent();
        if (jqXHR.status == 200) {
          fecharModalPrincipal();
          setTimeout(function () {
            $('#user-feedback-finish').modal('show');
          }, 500);
        }
      }).fail(function () {
        blockableContentFeedback.unblockContent();
        addMensagemError('Ocorreu um erro ao atender sua requisição, tente novamente, ou contacte +55 (61) 3216-3636.');
      });
    }
  });

  $('#user-feedback-form').on('shown.bs.modal', function () {
    $('#feedbackEmail').val('');
    $('#feedbackContent').val('');
  });

  $('#user-feedback-form').on('hidden.bs.modal', function () {
    $('#mensagemErroFeedback').hide();
  });

  function fecharModalPrincipal () {
    $('#user-feedback-form').modal('hide');
  }

  function addMensagemError (msg) {
    $('#textoMensagemErroFeedback').html(msg);
    $('#mensagemErroFeedback').show();
  }

  function isCamposValidos (email, mensagem) {
    if (mensagem.length > 500) {
      addMensagemError('Campo descrição: máximo de 500 caracteres');
      return false;
    }
    if (!isEmailValido(email)) {
      addMensagemError('Email inválido!');
      return false;
    }
    return true;
  }

  function isEmailValido (email) {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return re.test(String(email).toLowerCase());
  }

})(jQuery);
