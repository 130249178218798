$(document).ready(function () {

    inicializarPluginMmenu();

    configurarEstruturaMenuPrincipal();

    configurarLinksAdicionaisMenuMobile();

    configurarLogoHeaderMobile();

    function configurarEstruturaMenuPrincipal() {
        /*
         * Construindo o menu para desktop com as classes do bootstrap
         */
        var navbar = $('#navbarportal'),
            item,
            itemLink,
            subitem;

        // Navbar (conteiner)
        navbar.addClass('collapse navbar-collapse');
        // Lista de itens de primeiro nível
        navbar.find('> ul').addClass('navbar-nav');
        // Itens de primeiro nível
        item = navbar.find('> ul > li');
        item.addClass('nav-item dropdown');
        // Links dos itens de primeiro nível (inicialmente, em forma de span)
        itemLink = item.find('> span');
        // Transforma cada um dos span em link de verdade
        itemLink.each(function () {
            var link;
            // Cria um link com base no span
            link = spanToLink($(this));
            // Insere o link criado após o span original correspondente
            link.insertAfter($(this));
            // Remove o span original
            $(this).remove();
        });
        // Links dos itens de primeiro nível
        itemLink = item.find('> a');

        itemLink.addClass('nav-link dropdown-toggle').attr({
            'data-toggle': 'dropdown',
            'aria-haspopup': 'true',
            'aria-expanded': 'false',
        });
        // Lista de subitens
        item.find('> ul').addClass('dropdown-menu');
        // Subitens
        subitem = item.find('> ul > li');
        subitem.addClass('dropdown-item');
    }

    function inicializarPluginMmenu() {
        var offcanvasMenu;

        $('.js-mmenu').mmenu(
            {
                // OPTIONS //

                navbar: {
                    titleLink: 'none', // Sem link nos títulos dos painéis
                },

                keyboardNavigation: {
                    enable: 'default',
                    enhance: true,
                },
                extensions: ['pagedim-black'],
            },
            {
                // CONFIGURATIONS //

                clone: true, // Mantém o HTML original do menu intacto.

                screenReader: {
                    text: {
                        closeMenu: 'Fechar menu',
                        closeSubmenu: 'Fechar submenu',
                        openSubmenu: 'Abrir submenu',
                        toggleSubmenu: 'Alternar submenu',
                    },
                },
            }
        );

        // Por padrão (do mmenu), essa é a posição do menu offcanvas.
        // https://mmenujs.com/documentation/core/off-canvas.html
        // Note que o método padrão, que define a posição, é: prependTo(body)
        offcanvasMenu = $('body > :first-child');

        // Essa role permite que o leitor de tela se oriente melhor, não deixando o foco de
        // leitura ir além do conteúdo de cada painel do menu
        offcanvasMenu.find('> .mm-panels > .mm-panel').attr('role', 'dialog');
    }

    // Cria uma cópia de links das ferramentas de apoio dentro do painel principal do mmenu
    function configurarLinksAdicionaisMenuMobile() {
        // Painel principal criado pelo mmenu (primeiro nível do menu)
        var mainNav = $('#mm-main-nav'),
            // Links de apoio presentes no header - clona esse elemento
            linksApoio = $('header .links-apoio').clone(true);

        linksApoio.addClass('links-apoio--menu-mobile');

        // Retira dos links de apoio tudo o que não for um dos itens a seguir (Vlibras, Acessibilidade e Fale conosco)
        linksApoio
            .children()
            .not('.links-apoio__item--vlibras, .links-apoio__item--acessibilidade, .links-apoio__item--faleconosco')
            .remove();

        // Coloca os links de apoio selecionados dentro do painel principal,
        // envolvendo-os em uma classe que dá contexto para aplicação de estilos visuais.
        mainNav.append(linksApoio);
        linksApoio.wrap('<div class="outras-opcoes-menu-usuario"></div>');

        // Copiar as ferramentas de idioma
        var cloneIdiomas = $('header .lista-idiomas').clone();
        mainNav.append(cloneIdiomas);
        cloneIdiomas.find('.l-lista-idiomas').removeClass('dropdown-menu');

        // Copiar as opções de login
        var areaLogin = $('header .nav-top__botao-entrar').length
            ? $('header .nav-top__botao-entrar')
            : $('header .navbar-user');
        var cloneElementosLogin = areaLogin.clone();

        if (cloneElementosLogin.hasClass('navbar-user')) {
            // Configurar os conteineres da estrutura de login para o cenário 'logado'
            cloneElementosLogin.addClass('navbar-user--no-dropdown-mobile');
            cloneElementosLogin.find('.dropdown').removeClass('dropdown');
            cloneElementosLogin.find('.dropdown-menu').removeClass('dropdown-menu');

            let triggerDropdown = cloneElementosLogin.find('.navbar-user__logged-info');
            let triggerContent = triggerDropdown.html();

            // remover classes e atributos desnecessários do elemento que será transformado em span
            triggerDropdown.removeClass('dropdown-toggle').removeAttr('role, data-toggle, aria-expanded');

            // Aqui é necessário criar um novo span para substituir o link
            let novoSpan = $('<span></span>');
            novoSpan.append(triggerContent);
            novoSpan.attr('class', triggerDropdown.attr('class'));

            // Aplicar a substituição efetivamente
            novoSpan.prependTo(triggerDropdown.parent());
            triggerDropdown.detach();
        }
        var areaLoginMobile = $('<div class="area-login-menu-usuario"></div>');
        areaLoginMobile.append(cloneElementosLogin);
        mainNav.append(areaLoginMobile);
    }

    function configurarLogoHeaderMobile() {
        var logoOriginal = $('.logo-camara'),
            containerMenuGlobal = $('.menu-global--container'),
            cloneLogo = logoOriginal.clone();

        containerMenuGlobal.prepend(cloneLogo);
    }

    // Dado um span (representante de um item de primeiro nível no menu global),
    // transforma-o em um link (tag a), aproveitando id, class e conteúdo html.
    function spanToLink(span) {
        var link = $('<a href="#"></a>');

        link.attr('class', span.attr('class'));
        link.attr('id', span.attr('id'));
        link.html(span.html());

        return link;
    }
});
