import './includes/responsiveBreakpoints.js';
import './includes/globalsNoJquery.js';
import './includes/datepicker.js';
import './includes/header.js';
import './includes/vlibras.js';
import './includes/table2Chart.js';
import './includes/popover.js';
import './includes/timeline.js';
import './includes/slider.js';
import './includes/smooth-scrolling.js';
import './includes/background-loading.js';
import './includes/tab-dropdown.js';
import './includes/user-feedback.js';
import './includes/mejs-reset-on-hide.js';
import './includes/mejs-playlist.js';
import './includes/articleReadMore.js';
import './includes/fade-last-line.js';
import './includes/modal-video.js';
import './includes/compartilhamento.js';
import './includes/inlineCalendar.js';
import './includes/info-revealer';
import './includes/faux-link';
import './includes/youtubeLinked';
import './includes/monthPicker';
import './includes/multiselect.js';

/*
    Inicializações gerais - aquelas que valem para qualquer página do Portal
    Não coloque aqui código que dependa da existência de um elemento no DOM
    A não existência de um elemento deve provocar uma falha silenciosa, como
    ..ocorre com os métodos de procura de elementos do jQuery
*/

/**
 * MODELO DE COMPARTILHAMENTO
 * Se a página não pediu exclusão de compartilhamento:
 *  - montar compartilhamento misto se solicitado (usado para compartilhar conteúdo interno - url indicada pelo link - além do
 *      conteúdo principal - url  da página)
 *  - se o compartilhamento misto não foi solicitado, usar modelo antigo (para manter compatibilidade retroativa):
 *      - privilegiar a marcação antiga '.js-acao-compartilhamento'; 
 *      - se a marcação antiga não existir, montar o modelo novo: última ocorrência de '.g-contexto-compartilhamento'
 * 
 *  ATENÇÃO: as templates principais do portal recebem variáveis de configuração importantes. Consulte-as se necessário.
 */
if ($('.js-exclua-compartilhamento').length === 0) {
    if ($('.js-compartilhamento-misto').length > 0) {
        $('.js-acao-compartilhamento').configurarCompartilhamento();
        $('.g-contexto-compartilhamento')
            .last().montarEstruturaCompartilhamento(); 
    } else {
        if ($('.js-acao-compartilhamento').length > 0) { // preserva o modelo antigo, controlado pela página
            $('.js-acao-compartilhamento').configurarCompartilhamento();
        } else {
            const contextoCompartilhamento = $('.g-contexto-compartilhamento').last(); 
            contextoCompartilhamento.montarEstruturaCompartilhamento();
        }
    }
}
// Uma página pode marcar para não exibir o widget de compartilhamento, impressão e comentários
// if ($('.js-exclua-compartilhamento').length === 0) {
//     if ($('.js-acao-compartilhamento').length > 0) { // preserva o modelo antigo, controlado pela página
//         // Toda página que tem um elemento de compartilhamento, deve tê-lo iniciado 
//         $('.js-acao-compartilhamento').configurarCompartilhamento();
//     } else {
//         // Uma vez que mais de um nível de template pode incluir a classe de compartilhamento
//         // apenas a última ocorrência de ve ser considerada (compartilhamento modelo novo)
//         const contextoCompartilhamento = $('.g-contexto-compartilhamento').last(); 
//         // Toda página que marcar um conteiner como contexto de compartilhamento deve
//         // receber a estrutura de compartilhamento antes da inicialização
//         contextoCompartilhamento.montarEstruturaCompartilhamento();
//     }
// }


// Toda página que tem um elemento de impressão, deve tê-lo iniciado
$('.js-acao-impressao').on('click', function () {
    window.print();
});
