/*
 * Cria o objeto global RESPONSIVE (constante) com os valores adotados para os breakpoints
 * Isso replica os valores definidos em _variables.scss
 */
(function createResponsiveBreakpoints() {

    // Valores em pixels - largura mínima de cada breakpoint
    var minSM = 576,
        minMD = 768,
        minLG = 992,
        minXL = 1350;

    window.RESPONSIVE = {
        MIN: {
            XS: null, // Não há limite mínimo para XS
            SM: minSM,
            MD: minMD,
            LG: minLG,
            XL: minXL
        },
        // Os valores máximos são definidos em função do mínimo da faixa superior
        // Referência: https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
        MAX: {
            XS: minSM - 0.02,
            SM: minMD - 0.02,
            MD: minLG - 0.02,
            LG: minXL - 0.02,
            XL: null // Não há limite máximo para XL
        }
    };
})();