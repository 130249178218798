/**
 * Acrescenta funcionalidade default e extra ao popover.js - script usado pelo bootstrap para tooltip
 * - Fecha o tooltip aberto ao perder o foco e ao capturar a tecla ESC do teclado
 */

$(function () {

    // "Esconde" o popover original do Boostrap sob o nome bsPopover
    $.fn.bsPopover = $.fn.popover;

    $.fn.popover = function (options) {

        // Opções default do popover
        const DEFAULT_OPTIONS = {
            trigger: 'focus',
            template: '<div class="popover" role="tooltip"><div class="arrow"></div><span class=\'popover__close\' aria-hidden=\'true\'>&times;</span><h3 class="popover-header"></h3><div class="popover-body"></div></div>' 
        };

        // Aplicando as opções passadas por parâmetro (sobrescreve o default)
        options = $.extend({}, DEFAULT_OPTIONS, options);

        // Configuração geral do popover do bootstrap (para todos os elementos)
        this
            .bsPopover(options)
            .on('click', function(event) {
                event.preventDefault();
            });

        return this.each (function () {

            var mypop = $(this);

            mypop.on('show.bs.popover', function () {
                $(document).on('keyup', function (event) {
                    var key = event.key || event.keyCode;
                    if (event.defaultPrevented) {
                        return;
                    }
                    if (key === 'Escape' || key === 'Esc' || key === 27) {
                        mypop.bsPopover('hide');
                    }
                });
            });
            mypop.on('blur', function() {
                mypop.bsPopover('hide');
            });
        });
    };

    $('a[data-toggle="popover"]').each( (item, elem) => {
        if (!elem.getAttribute('tabindex')) {
            elem.setAttribute('tabindex','0');   
        }
    });
    $('[data-toggle="popover"]').popover();

});

/* -------------------------------------------
    Tooltip com listas e 
    - Possui lista em HTML
    - Pode ter links
    - Aparece com mouseover e some com mouseout, mas...
    - Fixa no clique e some no clique fora ou dentro do tooltip
    - apresenta um "X" para fechar em telas pequenas
    Exemplo:
    - Página RH e Concursos, bloco "QUAL A DISTRIBUIÇÃO DOS FUNCIONÁRIOS POR ÁREA DE ATUAÇÃO?"
    http://localhost:3002/tema/institucional/rh-concursos (não )
---------------------------------------------- */
$('.js-popover-with-list').each ( (idx, elem) => {
    const placement = $(elem).attr('data-placement') || 'auto';

    if (elem.tagName == 'A' && !elem.getAttribute('tabindex')) {
        elem.setAttribute('tabindex','0');   
    }

    $(elem).popover({
        template: getTemplate('popover--with-list'),
        trigger: 'hover focus',
        placement: placement
    });
});

/* -------------------------------------------
    Variação de Tooltip para o gráfico de barras empilhadas
    - Apresenta o título
    - Apresenta a descrição (normalmente um número)
    - Aparece com mouseover e some com mouseout, mas...
    - Fixa no clique e some no clique fora ou dentro do tooltip
    Exemplo:
    - Página RH e Concursos, bloco "TRABALHADORES TERCEIRIZADOS"
    http://localhost:3002/tema/institucional/rh-concursos
---------------------------------------------- */
$('.js-popover-title-text').each( (idx, elem) => {
    if (elem.tagName == 'A' && !elem.getAttribute('tabindex')) {
        elem.setAttribute('tabindex','0');   
    }

    $(elem).popover({
        template: getTemplate('popover--title-text'),
        trigger: 'hover focus'
    });
});

/* -------------------------------------------
    > Popover com Título e Descrição Versão 2 (com linha horizontal que separa título e texto)
    * Classe: .js-popover-title-text--v2
    
    Mais instruções no arquivo sass/components/_popover.scss
---------------------------------------------- */
$('.js-popover-title-text--v2').popover({
    template: getTemplate('popover--title-text-v2')
});

function getTemplate(mainClassName) {
    return `<div class="popover ${mainClassName}" role="tooltip"><div class="arrow"></div><span class="popover__close" aria-hidden="true">&times;</span><h3 class="popover-header"></h3><div class="popover-body" style="margin-bottom: -16px;"></div></div>`;
}
