/**
 * Fauxlink
 * Arquivo SCSS de apoio: src/global/sass/includes/components/_faux-link.scss
 * 
 * Aplica uma cópia do link interno sobrepondo a área do container  a fim de tornar todo ele 
 *  clicável, mantendo-o escondido de leitores de tela e de teclado
 * 
 * ATENÇÃO!!!: Use apenas se não tiver a oportunidade de construir a estrutura no HTML
 * 
 * Funcionamento:
 *  1) Aplica transformação sob as seguintes condições:
 *      - o elemento passado não é um link com atributo HREF
 *      - o elemento passado não possui um VIDEO, AUDIO ou IFRAME como descendente
 *      - o elemento passado possui apenas um link ou o link é indicado pela classe '.js-faux-link-target'
 *      - o elemento passado possui uma e somente uma imagem IMG como descendente
 *  2) Copia o link (único ou indicado) e acrescenta ao final do contêiner
 *  3) Transforma o container em referência de posicionamento (RELATIVE), com apoio do CSS
 *  4) Transforma o novo link acrescentado em elemento posicionado (ABSOLUTE) e o faz cobrir a área do contêiner, com apoio do CSS
 *  5) Se necessário, transforma os demais links descendentes em camadas superiores (Z-INDEX=1)
 * 
 * Como usar:
 *  - Se o elemento contêiner possui apenas um link, simplesmente chame o método 'applyFauxLink' no objeto jQuery que contém o elemento
 *  - Se ocorrer mais de um link como descendente do elemento contêiner, indique qual deve ser considerado, usando a classe 'js-faux-link-target' no elemento A
 *
 * Estrutura mínima recomendada para não precisar desse componente (outros elementos podem aparecer):
 * + container (DIV normalmente)
 * |--- imagem (IMG.faux-link)
 * |--- link (A[HREF]), livre ou dentro da estrutura que precisar (DIV, H3, ...)
 * |--- link repetido (A[HREF][TABINDEX=-1][ARIA-HIDDEN=true].faux-link__target)
 */
(function ($) {
    'use strict';

    var defaults = {

    };

    function validateStructure (element) {
        if (element.prop('tagName') == 'A') {
            return false;
        }
        if (element.find('a[href]').length != 1 && element.find('a[href].js-faux-link-target').length != 1) {
            return false;
        }
        if (element.find('video').length != 0) {
            return false;
        }
        if (element.find('audio').length != 0) {
            return false;
        }
        if (element.find('iframe').length != 0) {
            return false;
        }
        if (element.find('img, [role=img]').length == 0) {
            return false;
        }
        return true;
    }

    $.fn.applyFauxLink = function (options) {

        options = $.extend(false, {}, defaults, options);

        return this.each( function () {

            var container = $(this),
                targetLink,
                cloneLink,
                containerComputedStyle = window.getComputedStyle(container[0]),
                isTargetPointed = container.find('a[href].js-faux-link-target').length,
                additionalLinks;

            if (!validateStructure(container)) {
                return;
            }

            targetLink = isTargetPointed ? container.find('a[href].js-faux-link-target') : container.find('a[href]').first();
            targetLink.addClass('js-faux-link-target');
            additionalLinks = container.find('a[href]').not(targetLink);
            additionalLinks.css('z-index',1);
            cloneLink = targetLink.clone();
            cloneLink.find('[id]').each( function (key, elem) {
                elem = $(elem);
                elem.attr('id') && elem.attr('id', elem.attr('id')+'-faux');
            });
            cloneLink.attr('id') && cloneLink.attr('id',cloneLink.attr('id')+'-faux');
            cloneLink.attr({'aria-hidden':'true','tabindex':'-1'});
            cloneLink.addClass('faux-link__target');
            container.append(cloneLink);

            if (['absolute','fixed'].indexOf(containerComputedStyle.position) != -1) {
                container.children().wrapAll('<div class="faux-link"></div>');
                if (containerComputedStyle.display == 'flex') {
                    container.children().css(
                        {
                            'display':'flex',
                            'flex-grow': containerComputedStyle.flexGrow,
                            'flex-shrink': containerComputedStyle.flexShrink,
                            'flex-basis': containerComputedStyle.flexBasis,
                            'justify-content': containerComputedStyle.justifyContent,
                            'align-items': containerComputedStyle.alignItems,
                        });
                }
            } else {
                additionalLinks.each( function() {
                    if (['absolute', 'fixed'].indexOf(window.getComputedStyle(this).position) == -1) {
                        $(this).css('position','relative');
                    }
                });
                container.addClass('faux-link');
            }

        });
    };
})(jQuery);