/**
 * mejsResetOnHide
 *
 * Inicia uma instância do mediaelements (mejs) programada para voltar
 * ao estado original se o vídeo for escondido (não visível na tela),
 * como quando estiver em uma janela modal ou uma aba.
 *
 * Funciona da seguinte forma: Quando o vídeo é reproduzido (evento playing),
 * dispara uma função de monitoramento, executada de tempos em tempos.
 * Se essa função verifica que o nó que contém o vídeo não está visível,
 * faz o player voltar ao seu estado inicial (ou próximo disso).
 *
 * Uso:
 * - Crie a marcação necessária para o mejs.
 * - Não use a classe `mejs__player`.
 * - Instancie esse plugin no elemento que receberia a classe `mejs__player`. Por exemplo:
 *   Trecho da página:
 *       <video class="media-modal__video js-mejs-reset-on-hide" controls ... >
 *   Javascript executado pela página:
 *       $('.js-mejs-reset-on-hide').mejsResetOnHide();
 */
(function ($) {

    $.fn.mejsResetOnHide = function () {

        var INTERVAL_TIME = 500,
            basicOptions;

        // Cria os eventos necessários para fazer funcionar o monitoramento
        function createPlayingHandler (mediaElement, originalNode, instance) {

            // Variável que vai controlar o monitoramento necessário para reset quando escondido.
            var interval = undefined;

            // Quando o vídeo for reproduzido...
            $(mediaElement).on('playing', function () {

                // Inicia o monitoramento (se não tiver sido iniciado ainda)
                interval = interval || setInterval(function () {

                    // Caso o nó não esteja visível
                    if (!$(mediaElement).is(':visible')) {
                        // Força o player a voltar para o estado inicial
                        instance.pause();
                        instance.setCurrentTime(0);
                        $(mediaElement).closest('.mejs__container').find('.mejs__poster').show();
                        // Interrompe o monitoramento
                        clearInterval(interval);
                        interval = undefined;
                    }
                }, INTERVAL_TIME);
            });
        }

        // Opções iniciais para o mejs
        basicOptions = {
            success: createPlayingHandler
        };

        return this.each (function () {

            var player = $(this),
                // Obtém as opções passadas por meio do HTML (atributo data-mejsoptions)
                options = player.data('mejsoptions');

            // Estende as opções básicas utilizando as opções informadas por meio do HTML
            options = $.extend({}, basicOptions, options);

            // Cria a instância o mediaelements usando as opções definidas
            player.mediaelementplayer(options);
        });
    };

})(jQuery);