/**
 * mejsPlaylist
 *
 * Intancia o Mediaelements e seu plugin Playlist
 *
 * Funciona da seguinte forma: Configura os parâmetros básicos para
 * funcionamento do Plugin, que busca cada elemento 'source' interno
 * ao elemento 'video' e reproduz, um a um. A configuração básica
 * acrescenta os botões de 'próximo' e 'anterior'
 *
 * Uso:
 * - Crie a marcação necessária para o mejs.
 * - Não use a classe `mejs__player`.
 * - Instancie esse plugin no elemento que receberia a classe `mejs__player`. Por exemplo:
 *   Trecho da página:
 *       <video class="js-mejs-lista-reproducao" controls ... >
 *   Javascript executado pela página:
 *       $('.js-mejs-mejs-lista-reproducao').mejsPlaylist();
 */
(function ($) {

    $.fn.mejsPlaylist = function (customOptions) {

        var INTERVAL_TIME = 500,
            basicOptions;

        function umnome (mediaElement, originalNode, instance) {

            var prevButton = instance.prevButton;
            var nextButton = instance.nextButton;
    
            var items = $(originalNode).find('[data-playlist-item]');
            
            if (instance.playlist.length > 0 ) {
                mediaElement.setSrc(instance.playlist[0].src);
            }
            
            $(prevButton).css('opacity', '0.6');
            if (instance.playlist.length < 2) {
                $(nextButton).css('opacity', '0.6');
            }
    
            $(mediaElement).on('ended', function() {
                if ( instance.currentPlaylistItem < instance.playlist.length  ) {
                    mediaElement.setSrc(instance.playlist[instance.currentPlaylistItem].src);
                }
            });
            $(mediaElement).on('play', function() {
                $(mediaElement).trigger('newvideo',{sequence: instance.currentPlaylistItem});
                if (instance.currentPlaylistItem === 0) {
                    $(prevButton).css('opacity', '0.6');
                } else {
                    $(prevButton).css('opacity', '');
                }
                if (instance.currentPlaylistItem === instance.playlist.length - 1) {
                    $(nextButton).css('opacity', '0.6');
                } else {
                    $(nextButton).css('opacity', '');
                }
        
            });
            $(window).on('error', function(evt) {
                // Captura e ignora erro não tratado do plugin ao terminar o último vídeo 
                if( evt.originalEvent.error.stack.indexOf('at player.endedCallback') >= 0 
                    && evt.originalEvent.error.stack.indexOf('[as setSrc]') >= 0) {
                    return false;
                }
            });
        }


        // Opções iniciais para o mejs
        basicOptions = {
            success: umnome,
            features: [ 'playpause', 
                'prevtrack', 
                'nexttrack', 
                'current', 
                'progress', 
                'duration', 
                'volume', 
                'playlist', 
                'fullscreen' ],
            stretching: 'responsive',
            showPlaylist: false,
            prevText: 'trecho anterior',
            nextText: 'próximo trecho'
        };

        return this.each (function () {

            var player = $(this),
                // Obtém as opções passadas por meio do HTML (atributo data-mejsoptions)
                options = customOptions || player.data('mejsoptions');

            // Estende as opções básicas utilizando as opções informadas por meio do HTML
            options = $.extend({}, basicOptions, options);

            // Cria a instância o mediaelements usando as opções definidas
            player.mediaelementplayer(options);
        });
    };

})(jQuery);
