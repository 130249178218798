/* global google */

/* A página que for usar esse plugin deve incluir uma chamada ao script "https://www.gstatic.com/charts/loader.js"
 * Tipicamente, isso é feito dentro de "custom-scripts-block", assim:
    {{#*inline "custom-scripts-block"}}
        <script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
    {{/inline}}
 */

/**
 * table2Chart
 * Converte uma tabela regular em um gráfico 
 *  OBS: Uma tabela que não atenda aos critérios mínimos não será convertida. 
 *       Uma mensagem deve ser passada quando não houverem dados a informar (início de período ou outra condição)
 *       A mensagem deve ser passada no atributo 'aria-label' da tabela vazia (tbody sem tr)
 *       Sem a presença do atributo, o valor do elemento filho 'caption' será usado como mensagem
 * Marcação HTML:
 * - A tabela deve ter seu valor id especificado.
 * - A tabela deve estar marcada com a classe js-chart
 * - A tabela deve estar marcada com uma das classes:
 *   - js-chart--pie (default): cria um gráfico de pizza, o mesmo resultado se não aplicar essa classe
 *   - js-chart--bar: cria um gráfico de barras genérico, com linhas de referência para os valores maior e menor.
 *   - js-chart--h-axis-labeled: cria um gráfico de barras com rótulos no eixo horizontal.
 *   - js-chart--limit (usar em conjunto com h-axis-labeled: apresenta também uma linha de "limite" no gráfico de barras com rótulos no eixo horizontal).
 *   - js-chart--treemap: cria um gráfico do tipo treemap
 * - Uma área para o desenho do gráfico, com id esepcífico ('id-da-tabela' + '-chart') deve ser designada.
 *   - Ex.: uma tabela com id 'table-1-chart' será desenhada em um elemento (div, p. ex.) com id 'table-1-chart'
 *
 * Inicialização:
 * $('my-selector').table2Chart(options);
 *
 * Options (opcional):
 * Quando informado, é um objeto literal com as opções abaixo:
    {
        tooltip: function (row) { }, // Callback para tooltip customizado
        showReferenceLines: false, // Determina que o gráfico de barras não será apresentado com linhas horizontais de referência
        initialSelection: 'ultimo' // Para gráfico de barras e suas variações, determina que a seleção original (c/tooltip aberto) será a última barra do gráfico
                                    ... e não a de valor mais alto(default)
    }

* Documentação da função de callback para tooltip customizado
    Essa função recebe como parâmetro um array representando um linha da tabela.
    Deve retornar um objeto no formato abaixo (todas as chaves são opcionais):
    {
        data {
            label:          'label',        // Rótulo
            value:          'value',        // Valor
            percent:        'percent',      // Percentual (do valor em relação ao total)
            percentLimit:   'percentLimit'  // Percentual do limite (do valor em relação ao limite)
        },
        condensed:          true            // false por padrão. Quando true, tenta manter valor e percentual na mesma linha.
    }

 * Exemplo do uso de options

$('#my-table-id').table2Chart({
    tooltip: function (row) {
        return ({
            data: {
                label: row[0],               // Primeira coluna é o label
                value: 'R$ ' + row[1],       // Segunda coluna é o value (com o sufixo R$)
                percent: '(' + row[2] + ')'  // Terceira coluna é o percentual (entre parênteses)
            },
            condensed: true                  // Solicita manter value e percent na mesma linha (se possível)
        });
    }
});

* Documentação de referência para a opção 'showReferenceLines'
    Por default os gráficos de barra apreentarão duas linhas horizontais com respectivos rótulos,
    para demonstrar o espaço de valores aproximado (minimo e máximo) em que as barras estão inseridas.
    Adicionalmente, o gráfico tentará desenhar a barra mais baixa na altura correspondente a um percentual (75%, p. ex.) da 
    diferença entre o maior e o menor valor designado no gráfico

    Quando a opção for por não apresentar as linhas de referência, informe a propriedade 'showReferenceLines' com valor 'false'
 *
 */
(function ($) {
    'use scrict';

    var BAR_COLORS_DEFAULT = ['#007C7C', '#05CCEF', '#FAC915', '#0FA87F', '#C43BB0', '#F39125', '#1C91D1', '#FFDE6F', '#BB0C43', '#39C5BE', '#FF7295', '#FFC178'];
    var PIE_COLORS_DEFAULT = ['#007C7C', '#05CCEF', '#FAC915', '#0FA87F', '#C43BB0', '#F39125', '#1C91D1', '#FFDE6F', '#BB0C43', '#39C5BE', '#FF7295', '#FFC178'];

    var CHARTCRITERIA = {
            body: {
                required: true
            },
            head: {
                required: true
            },
            table: {
                regular: true
            }
        };

    // Opções básicas - Afetam todos os gráficos
    var CHART_OPTIONS = {
            legend: 'none',
            tooltip: {
                trigger: 'selection',
                isHtml: false,
                textStyle: {
                    fontName: 'Open Sans',
                    fontSize: '14'
                }
            },
            chartArea: {left:'7.5%', top:'7.5%', width: '85%', height:'85%'}
        };

    // Opções dos gráficos de pizza - Estende de CHART
    var PIE_OPTIONS = $.extend(true, {}, CHART_OPTIONS, {
            colors: PIE_COLORS_DEFAULT,
            pieSliceText: 'none'
        });

    // Opções dos gráficos de barras - Estende de CHART
    var BAR_OPTIONS = $.extend(true, {}, CHART_OPTIONS, {
            hAxis: {
                textPosition: 'none'
            },
            vAxis: {
                textPosition: 'none',
                baselineColor: '#cdcdcd',
                gridlines: {
                    count: 0,
                    color: 'transparent'
                },
                viewWindowMode: 'maximized'
            }
        });

    // Opções dos gráficos com rótulos no eixo horizontal (especialização de gráfico de barras) - Estende de BAR
    var H_AXIS_LABELED_OPTIONS = $.extend(true, {}, BAR_OPTIONS, {
            hAxis: {
                textPosition: 'out',
                textStyle: {
                    color: '#5e5e5e'
                }
            },
            chartArea: { height:'75%' },
        });

    // Opções adicionais para modificador 'limit'
    // Atualmente, usado somente em conjunto com H_AXIS_LABELED
    var LIMIT_OPTIONS = {
            seriesType: 'bars',
            series: {
                // Linha "Limite"
                1: {
                    type: 'line',
                    color: '#cdcdcd',
                    lineDashStyle: [5,5],
                    lineWidth: 1,
                    // Isso impede qualquer interação com essa linha, para que o tooltip
                    // funcione bem como projetado, ou seja, mostrando toda a informação junta.
                    // Mas somente a parte das barras fica interativa, não a linha "Limite"
                    enableInteractivity: false
                }
            }
        };

    // Opções para quando é solicitado o gráfico de barras com linhas de referência
    var REFERENCE_LINES_OPTIONS = {
        vAxis: {
            textPosition: 'out',
            baselineColor: '#cdcdcd',
            gridlines: {
                color: '#8d8d8e'
            },
            textStyle: { 
                color: '#5f5f5e',
                fontSize: 11,
                bold: true,
            },
            viewWindowMode: 'pretty',
            viewWindow: {
            },
            format: 'short',
            ticks: [
            ]    
        },
        chartArea: {left:'13%', top:'10%', width: '100%', height:'70%'}
    };
    var TREEMAP_TOOLTIP_CLASS = 'js-treemap-tooltip';

    var TREEMAP_OPTIONS = $.extend(true, {}, CHART_OPTIONS, {

            highlightOnMouseOver: true,
            minColor: '#FF9900',
            midColor: '#1E9EDC',
            maxColor: '#E66678',
            headerHeight: 0,
            fontFamily: 'Open Sans',
            fontColor: 'black',
            showScale: false,
            tooltip: undefined, // 'tooltip' não é uma opção válida para treemap
    });


    var PIE_ASPECT_RATIO = {
        height: 1,
        width: 1.5
    };

    var BAR_ASPECT_RATIO = {
        height: 5,
        width: 8
    };


    var INITIAL_SELECTION = {
        ultimo: 'ultimo'
    };

    var creator = {};


    // Verifica se a tabela atende aos critérios especificados, retornando
    // - true caso atenda
    // - false caso não atenda
    function checkTable (table, criteria) {

        // Verifica se a tabela é "regular", ou seja, se todas as linhas possuem o mesmo número de células
        // Aqui, tanto TH quanto TD são considerados células.
        // Retorna:
        // - true caso a tabela seja regular
        // - false caso não seja regular
        function isRegular (table) {
            var cells,
                regular = true;

            table.find('tr').each(function (index) {

                // Na primeira iteração, apenas armazena a quantidade de células da linha
                // Esse número será utilizado depois na comparação com todas as demais linhas
                if (index === 0) {
                    cells = $(this).find('th, td').length;
                }
                else {
                    // Se o número de células de qualquer linha for diferente do número de células da primeira linha
                    if ($(this).find('th, td').length !== cells) {
                        // Informa que a tabela não é regular
                        regular = false;
                        // Interrompe o loop 'each'
                        return false;
                    }
                }
            });

            return regular;
        }

        var actual = {
                table: {},
                head: {},
                body: {}
            };

        // -- Obtenção das características da tabela -- //
        // Verifica se há tbody válido - significa body com ao menos 1 linha
        actual.body.isPresent = (table.find('tbody tr').length > 0);
//        actual.body.isPresent = (table.find('tbody').length !== 0);
        // Verifica quantas linhas há no tbody
        actual.body.rows = table.find('tbody tr').length;
        // Verifica se há thead
        actual.head.isPresent = (table.find('thead').length !== 0);
        // Verifica quantas linhas há no thead
        actual.head.rows = table.find('thead tr').length;
        // Verifica se a tabela é regular
        actual.table.regular = isRegular(table);

        // -- Verificações -- //
        // Se body é obrigatório e não vazio
        if (criteria.body.required) {
            // Retorna false se body não existir
            if (!actual.body.isPresent) return false;
        }
        // Se foi definido um número de linhas no body
        if (criteria.body.rows) {
            // Retorna false se o número de linhas não corresponder ao esperado.
            if (criteria.body.rows !== actual.body.rows) return false;
        }
        // Se head é obrigatório
        if (criteria.head.required) {
            // Retorna false se head não existir.
            if (!actual.head.isPresent) return false;
        }
        // Se foi definido um númeor de linhas no head
        if (criteria.head.rows) {
            // Retorna false se o número de linhas não corresponder ao esperado.
            if (criteria.head.rows !== actual.head.rows) return false;
        }
        // Se a tabela deve ser regular
        if (criteria.table.regular) {
            // Retorna false se não for regular.
            if (!actual.table.regular) return false;
        }
        // Retorna true se chegou até aqui, passando por todos os testes.
        return true;
    }

    function renderNoData (table) {
        var svgPie = `<svg width="206" height="206" viewBox="0 0 206 206" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="103" cy="103" r="98.5459" fill="#BDBDBD"/>
        <circle cx="103" cy="103" r="101.773" stroke="#BDBDBD" stroke-width="2"/>
        </svg>
        `;
        var svgBar = `<svg width="395" height="101" viewBox="0 0 395 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 100L395 100" stroke="#DFDFDF"/>
        <rect x="90" width="35" height="100" fill="#BDBDBD"/>
        <rect x="135" width="35" height="100" fill="#BDBDBD"/>
        <rect x="180" width="35" height="100" fill="#BDBDBD"/>
        <rect x="225" width="35" height="100" fill="#BDBDBD"/>
        <rect x="270" width="35" height="100" fill="#BDBDBD"/>
        <rect x="92.5" y="2.5" width="30" height="95" stroke="white"/>
        </svg>
        `;
        var caption = $(table.element).find('caption');
        var description = caption.find('.js-chart--no-data');
        var mensagem = $.trim(description.text()) || (caption.length ? $.trim(caption.text()) : false) || false;

        if (mensagem) {
            // montar a estrutura para comunicar visualmente, de acordo com o tipo do gráfico
            var paragraph = {
                pie: function () {
                    table.element.before(`<p class="no-data-chart no-data-chart--pie">${svgPie}<span class="no-data-chart__message">${mensagem}</span></p>`);
                },
                bar: function () {
                    table.element.before(`<p class="no-data-chart no-data-chart--bar">${svgBar}<span class="no-data-chart__message">${mensagem}</span></p>`);
                },
                hAxisLabeled: function () {
                    table.element.before(`<p class="no-data-chart no-data-chart--bar">${svgBar}<span class="no-data-chart__message">${mensagem}</span></p>`);
                }

            };

            paragraph[table.type] && paragraph[table.type]();
            table.element.wrapAll('<div class="sr-only"></div>');
        } else {
            // Retornar sem montar nem o gráfico nem a mensage, logando o erro
            console.error('A tabela não está no formato esperado para gerar um gráfico. Tabela: ', table, ' Critérios', CHARTCRITERIA);
            console.error('Nenhuma tag caption com conteúdo útil para a tabela foi recebida da aplicação!');
        } 
    }

    function tbodyToArray(tableElement, cols = 0) {

        var rows,
            tableArray = [];

        // Pega as linhas do tbody da tabela
        rows = tableElement.find('tbody tr');

        // Para cada linha da tabela
        rows.map(function (rowCount, row) {

            var cells = $(row).find('th, td');

            // Se foi especificado um número limite de colunas, filtra as colunas por esse número
            if (cols > 0) {
                cells = cells.filter(':lt('+ cols +')');
            }

            tableArray[rowCount] = [];

            cells.map(function (colCount, cell) {

                tableArray[rowCount][colCount] = $(cell).text();
            });
        });

        return tableArray;
    }

    // Cria o collapse contendo a legenda (bloco BEM: legend-collapse)
    // - legend: HTML com o conteúdo da legenda
    // - referenceId: o id de referência de onde se derivam os ids criados dinamicamente
    // Retorna a string HTML dos elementos criados
    function createLegendCollapse (legend, referenceId) {

        var legendCollapse,
            collapsbileId;

        collapsbileId = `${referenceId}-legend`;

        // Criando o trigger (<a>) e o target (<div>)
        legendCollapse =
            `<a data-toggle="collapse" href="#${collapsbileId}" class="legend-collapse__trigger collapsed" role="button" aria-expanded="false" aria-controls="${collapsbileId}">
            <span class="fas fa-list-ul legend-collapse__icon"></span> Legenda
            </a>
            <div class="collapse" id="${collapsbileId}">
                <div class="legend-collapse__content">
                    ${legend}
                </div>
            </div>`;

        // Agrupando tudo em uma div
        legendCollapse = `<div class="legend-collapse">${legendCollapse}</div>`;

        return legendCollapse;
    }

    // Cria a legenda para o gráfico (bloco BEM: legend)
    // - labels: array de string com os rótulos para as legendas
    // - chartType: tipo de gráfico que está sendo criado
    // Retorna a string HTML dos elementos criados
    function createLegend (labels, chartType='') {
        var legend = '',
            modificator;

        modificator = chartType ? `legend--${chartType}` : '';

        for (var i=0; i < labels.length; i++) {

            legend += `<li class="legend__category">
                            <a href="#" class="js-open-tooltip" data-category="${i}" aria-label="${labels[i]} (mostrar detalhe no gráfico)">
                                ${labels[i]}
                            </a>
                       </li>`;
        }

        // Adicionando os conteineres
        legend = `<ul class="legend__categories">${legend}</ul>`;
        legend = `<div class="legend ${modificator}">${legend}</div>`;

        return legend;
    }

    // Insere a legenda do gráfico ao final do conteiner destinado ao gráfico (sufixo -chart)
    // - data: array com os dados do gráfico
    // - referenceId: o id de referência de onde se derivam os ids criados dinamicamente
    // - chartType: tipo de gráfico que está sendo criado
    function insertLegend (data, referenceId, chartType) {

        var legend,
            container = $(`#${referenceId}-chart`);

        // Cria o HTML da legenda
        legend = createLegend(getColumn(data, 0), chartType);
        // coloca o HTML da legenda dentro do HTML do collapse
        legend = createLegendCollapse(legend, referenceId);
        //  e insere no final do container
        container.append(legend);
    }

    function proportionalHeight (width, ratio) {

        // Regra de 3 para definir a altura proporcional;
        return (ratio.height * width) / ratio.width;
    }

    function configureHorizontalAxis(table, width) {
        var options,
            tableLength = table.getNumberOfRows(),
            hAxis = {};

        // Define as situações em que o texto é mostrado de forma inclinada;
        if ((width <= 300) ||
            (width <= 490 && tableLength <= 6) ||
            (width >  490 && tableLength <= 9)) {

            hAxis['slantedText'] = false;
        }
        else {
            hAxis['slantedText'] = true;
        }

        // Define de quantos em quantas séries aparecerá o rótulo
        if (width <= 300) {
            // Mostra o primeiro e o último
            hAxis['showTextEvery'] = tableLength - 1;
        }
        else {
            // Mostra o todos
            hAxis['showTextEvery'] = 1;
        }

        // Define quando deve forçar que apenas uma linha seja mostrada
        // Para evitar duas linhas de texto no eixo (que é o padrão)
        if (((300 < width <= 490) && tableLength <= 6 ) ||
            ((490 < width)        && tableLength <= 9)) {

            hAxis['maxAlternation'] = 1;
        }

        options = {
            hAxis: hAxis
        };

        return options;
    }


    /*
     * Converte um string que representa um número "em português" para um número
     * Por exemplo: "1.234,66" >> 1234.66
     */
    function stringToNumber(string) {

        var number,
            converting = string;

        // Remove espaços em branco antes e depois
        converting = converting.trim();
        // Remove os agrupadores de milhar (quantos houver)
        converting = converting.replace(/\./g, '');
        // Transforma a ',' (separador de decimal) em '.'
        converting = converting.replace(',', '.');
        // Finalmente, converte o tipo, retornando
        try {
            number = parseFloat(converting);
        }
        catch(e) {
            console.error('Impossível converter ' + string + ' para número.');
            number = 0;
        }

        return number;
    }

    // Obtém um array unidimensional (coluna) a partir de um array bidimensional (tabela)
    function getColumn (table, colIndex) {
        var column;

        column = $.map(table, function (row) {
            return row[colIndex];
        });

        return column;
    }

    // Acrescenta um array unidimensional (coluna) à "direita" de um array bidimensional (tabela)
    // Ou seja, acrescenta uma coluna ao final da tabela.
    function pushColumn(table, column) {

        table = $.map(table, function(row, i) {

            row.push(column[i]);
            return [row];
        });

        return table;
    }


    // Obtém o índice onde está o maior valor do array
    // Caso o maior valor apareça em mais de uma posição, considera sempre a primeira ocorrência.
    function getMaxValueIndex (array) {

        var maxValueIndex = 0,
            maxValue = array[0];

        for (var i = 1; i < array.length; i++) {
            if (array[i] > maxValue) {
                maxValueIndex = i;
                maxValue = array[maxValueIndex];
            }
        }
        return maxValueIndex;
    }

    /*
     * Faz o 'casting' de um string de acordo com o formato informado.
     * O formato pode ser um dos seguintes:
     * - 'number': numérico
     * - 'integer': inteiro
     * - '': esse formato (vazio) faz com que o valor retornado seja null.
     *       Em conjunto com as outras funções que fazem 'casting' de array, isso faz com
     *       que o valor seja removido do array.
     * Se o formato for qualquer outra coisa, retorna o string original.
     */
    function castString (original, format) {
        var casted;

        switch (format) {
            case 'number': // Número
                casted = stringToNumber(original);
                break;

            case 'integer': // Número inteiro
                casted = parseInt(stringToNumber(original));
                break;

            case '': // Vazio
                casted = null;
                break;

            default: // Para qualquer outro caso, retorna o mesmo valor
                casted = original;
                break;
        }
        return casted;
    }

    /*
     * Faz o 'casting' de um array de strings (row) de acordo com o array de formatos informado.
     * Retorna um array de valores com no máximo o mesmo tamanho do array de formatos, ou seja,
     * despreza os valores para os quais não foi especificado um formato.
     */
    function castRow(row, formatArray) {

        row = row.slice(0, formatArray.length);

        row = $.map(row, function (cell, col) {
            return castString(cell, formatArray[col]);
        });

        return row;
    }

    /*
     * Faz o 'casting' de um array bidimensional de strings (table) de acordo com o array de formatos informado.
     * O 'casting' é feito linha a linha.
     * Retorna um array de valores em que cada linha tem no máximo o mesmo tamanho do array de formatos, ou seja,
     * despreza os valores para os quais não foi especificado um formato.
     */
    function castTable(table, formatArray) {

        table = $.map(table, function (row) {
            return [castRow(row, formatArray)];
        });

        return table;
    }

    /*
     * Cria, insere no DOM e retorna um container para o grid
     * A posição no DOM depende do id informado como parâmetro
     */
    function createGridContainer (id, type) {
        var gridContainer,
            chartContainer,
            typeChartModifier;

        typeChartModifier = type ? `chart--${type}` : '';

        // O container do gráfico é o elemento identificado com o mesmo id da tabela, com o sufixo '-chart'
        chartContainer = $(`#${id}-chart`);
        chartContainer.addClass(`chart ${typeChartModifier}`);
        gridContainer = $('<div></div>');
        gridContainer.appendTo(chartContainer);

        return gridContainer;
    }

    function createResizeHandler (drawCallback, datatable, chart, gridContainer, options) {

        // Armazena a largura original do container
        var width = gridContainer.width();

        function resizeHanlder () {
            // Verifica se a largura do container foi modificada desde a última chamada
            if (width !== gridContainer.width()) {
                width = gridContainer.width();
                drawCallback(datatable, chart, gridContainer, options);
            }
        }

        $(window).on('resize', resizeHanlder);
    }

    function  createShownTabHandler (drawCallback, datatable, chart, gridContainer) {

        // Monitora o evento de mostrar aba para toda a página
        $(window).on('shown.bs.tab', function (e) {

            // Aba que está sendo mostrada:
            // É o elemento que tem o 'id' informado no 'href' do link clicado
            var tabShown = $( $(e.target).attr('href') );

            // Se a aba mostrada contém o gráfico em questão...
            if ($.contains(tabShown.get(0), gridContainer.get(0))) {
                // ... redesenha o gráfico
                drawCallback(datatable, chart, gridContainer);
            }
        });
    }

    /*
     * Cria um span em torno da última palavra do label recebido como parâmetro, concatenado com um caracter &nbsp.
     * Essa função é uma forma de resolver um problema de cálculo de tamanho para o container
     * do tooltip do Google Chart, que em alguns casos provoca a quebra da última letra da palavra
     * para outra linha.
     */
    function createUnbreakSpan(label) {

        var labelArray;

        // Isso transforma "um string qualquer" em "um string <span class="_chart-tooltip-unbreak">qualquer&nbsp;</span>"
        labelArray = label.split(' ');
        labelArray[labelArray.length - 1] = `<span class="_chart-tooltip-unbreak">${labelArray[labelArray.length - 1]}&nbsp;</span>`;
        label = labelArray.join(' ');

        return label;
    }

    function ellipsisLabel (label, table) {
        
        var charPerLine = function () {
            if (table.gridContainerWidth >= 350) {
                return 55;
            }
            if (table.gridContainerWidth >= 220) {
                return 45;
            }
            return 35;
        }();
        if (label.length <= charPerLine) {
            return label;
        }
        var labelArray = label.split(' ');
        var accumulatedLength = 0;

        labelArray = labelArray.map( function(item, idx) {
            if (accumulatedLength >= charPerLine) {
                return '';
            }
            accumulatedLength += (item.length + 1); // o tamanho da palavra mas o espaço subsequente
            if (accumulatedLength >= charPerLine && idx != labelArray.length - 1 ) {
                item = '...'; 
            } 
            return item;
        });
        return labelArray.join(' ');
    }

    function getTooltipFontSize(container) {
        var fontSize = function () {
            if (container.width() >= 220) {
                return 14;
            }
            return 12;
        }();
        return fontSize;
    }
    /*
     * Cria o conteiner comum a todos os tooltips
     */
    function tooltipHtml (content, series, modifier) {

        modifier = modifier ? modifier : '';

        var tooltip =
            `<div class="chart-tooltip ${modifier} chart-tooltip--series-${series+1}">
                <p class="sr-only">Detalhe da linha ${series+1} da tabela no gráfico</p>
                <div class="chart-tooltip__content">
                    ${content}
                </div>
                <a href="#" class="chart-tooltip__close js-chart-tooltip-close" aria-label="Fechar detalhe"><span aria-hidden="true">&times;</span></a>
            </div>`;

        return tooltip;
    }
    function formatTooltipNonHTML (content) {

        content = $(`<div>${content}</div>`);

        var tooltip = `${content.find('.chart-tooltip__label').text()}\n${content.find('.chart-tooltip__value').text()}`;
        if (content.find('.chart-tooltip__percent').length){
            tooltip += `${content.find('.chart-tooltip__percent').text()}`;
        }

        return tooltip;
    }

    /*
     * Transforma o objeto data (conteúdo de um tooltip customizado) na marcação HTML correspondente.
     * Esse será o conteúdo do tooltip customizado.
     */
    function handleTooltipData(data, table) {

        var tooltip = '';

        // Evita tentar encontrar propriedades caso data não seja um objeto.
        if (typeof data !== 'object') {
            return tooltip;
        }

        if (data.label) {
            data.label =  ellipsisLabel(data.label, table);
            tooltip += `<span class="chart-tooltip__label">${data.label}</span> `;
        }
        if (data.value) {
            tooltip += `<span class="chart-tooltip__value">${data.value}</span> `;
        }
        if (data.percent) {
            tooltip += `<span class="chart-tooltip__percent">${data.percent}</span> `;
        }
        if (data.percentLimit) {
            tooltip += `<span class="chart-tooltip__percent-limit">${data.percentLimit}</span> `;
        }

        return tooltip;
    }


    function createTooltipBar (tbodyArray, table) {

        var column;

        column = $.map(tbodyArray, function (row, index) {

            var label,
                tooltipContent;

            label = ellipsisLabel(row[0], table);

            tooltipContent =
                `<span class="chart-tooltip__label">${label}</span>
                <span class="chart-tooltip__value">${row[1]}</span>`;
            return formatTooltipNonHTML(tooltipContent, index);
        });

        return column;
    }

    /*
     * Cria um tooltip customizado,
     * com base em uma função passada como parâmetro
     */
    function createTooltipCustom (tbodyArray, tooltipCallback, table) {

        var column;

        // Uma "coluna" de tooltips para cada linha da tabela (tbodyArray)
        column = $.map(tbodyArray, function (row, index) {

            var tooltipContent,
                tooltipInfo,
                modifier;

            // Obtém as informações que definem o tooltip
            tooltipInfo = tooltipCallback(row);
            // Obtém o conteúdo do tooltip com base nos dados informados
            tooltipContent = handleTooltipData(tooltipInfo.data, table);
            // Controla a aplicação de modificadores
            if (tooltipInfo.condensed) {
                modifier = 'chart-tooltip--condensed';
            }
            // Retorna o HTML completo do tooltip
            return formatTooltipNonHTML(tooltipContent, index, modifier);
        });

        return column;
    }

    function createTooltip (tbodyArray, table) {

        var column,
            customTooltipCallback;

        customTooltipCallback = table.options.tooltip;

        // Se foi definido um tooltip customizado (em forma de uma função de callback, é o que se espera)
        if (typeof customTooltipCallback === 'function') {
            // Cria os tooltip de acordo com a função de customização
            column = createTooltipCustom(tbodyArray, customTooltipCallback, table);
        }
        else {
            // Cria os tooltip de acordo com a função definida para o tipo de gráfico (objeto charts)
            column = charts[table.type].createTooltip(tbodyArray, table);
        }

        return column;
    }

    function getReferenceLinesConfig (datatable) {
        var RANGE_PROPORTION = 0.75;
        var LOWER_GROUND_LEVEL = 0;
        var VALUE_COLUMN_INDEX = 1;

        var values = datatable.getDistinctValues(VALUE_COLUMN_INDEX);
        var lowerValue = values[0];
        var higherValue = values[values.length-1];

        return $.extend(true, {}, REFERENCE_LINES_OPTIONS, {
            vAxis: {
                viewWindow: {
                    min: Math.max( lowerValue - (higherValue - lowerValue) * RANGE_PROPORTION, LOWER_GROUND_LEVEL ),
                    max: higherValue 
                },
                ticks: [ 
                    lowerValue,
                    higherValue
                ]        
            }
        });
    }
    var charts = {

        pie: {
            visualizationName: 'PieChart',

            createDataStructure: function (datatable, table) {

                var tableElement = table.element,
                    tbodyArray = tbodyToArray(tableElement),
                    castedArray,
                    greatestValueRow;

                //table.options.tooltip = table.options.tooltip || {};
                // Processa os dados obtidos da tabela,
                // mantendo apenas as colunas especificadas,
                // e transformando de string para o tipo especificado.
                castedArray = castTable(tbodyArray, ['string', 'number']);
                castedArray = pushColumn(castedArray, createTooltip(tbodyArray, table));

                // Dada uma coluna, obtém a linha que possui o valor maior
                greatestValueRow = getMaxValueIndex(getColumn(castedArray, 1));

                // Insere os dados no datatable que dará origem ao gráfico
                datatable.addColumn({type: 'string', label: 'Rótulo'});
                datatable.addColumn({type: 'number', label: 'Valor'});
                datatable.addColumn({type: 'string', role: 'tooltip', p: {html: false}});
                datatable.addRows(castedArray);

                // Idealmente, não deveria estar aqui, mas é legado da
                // estrutura anterior que misturava ainda mais as coisas.
                insertLegend(castedArray, tableElement.attr('id'));

                return [{row: greatestValueRow}];
            },

            createTooltip: function (tbodyArray, table) {

                var column;

                column = $.map(tbodyArray, function (row, index) {

                    var label,
                        tooltipContent;

                    label = ellipsisLabel(row[0], table);

                    tooltipContent =
                        `<span class="chart-tooltip__label">${label}</span>
                        <span class="chart-tooltip__value">${row[1]}</span>
                        <span class="chart-tooltip__percent"> (${row[2]})</span>`;

                    return formatTooltipNonHTML(tooltipContent, index, 'chart-tooltip--condensed');
                });

                return column;

            },

            drawChart: function (datatable, chart, gridContainer) {
                // Desenha o gráfico:
                // Define de forma proporcional a altura do gridContainer
                gridContainer.css('height', proportionalHeight(gridContainer.width(), PIE_ASPECT_RATIO));
                // Coloca na tela!
                chart.draw(datatable, $.extend(true, PIE_OPTIONS,
                    {
                        tooltip: {
                            textStyle: {
                                fontSize: getTooltipFontSize(gridContainer)
                            }
                        }
                    }));

            },

            setSelection: function (chart, category) {
                chart.setSelection([{row: category}]);
            }
        },

        bar: {

            visualizationName: 'ColumnChart',

            createDataStructure: function (datatable, table) {

                var tableElement = table.element,
                    tbodyArray = tbodyToArray(tableElement),
                    castedArray,
                    initialSelectionRow,
                    initialSelection = table.options.initialSelection;

                // Processa os dados obtidos da tabela,
                // mantendo apenas as colunas especificadas,
                // e transformando de string para o tipo especificado.
                castedArray = castTable(tbodyArray, ['string', 'number']);
                castedArray = pushColumn(castedArray, BAR_COLORS_DEFAULT);
                castedArray = pushColumn(castedArray, createTooltip(tbodyArray, table));


                // Dada uma coluna, obtém a linha que possui o valor maior
                initialSelectionRow = (function () {
                    return (initialSelection === INITIAL_SELECTION.ultimo) ? castedArray.length - 1: getMaxValueIndex(getColumn(castedArray, 1));
                })();

                // Insere os dados no datatable que dará origem ao gráfico
                datatable.addColumn({type: 'string', label: 'Rótulo'});
                datatable.addColumn({type: 'number', label: 'Valor'});
                datatable.addColumn({type: 'string', role: 'style'});
                datatable.addColumn({type: 'string', role: 'tooltip', p: {html: false}});
                datatable.addRows(castedArray);

                // Idealmente, não deveria estar aqui, mas é legado da
                // estrutura anterior que misturava ainda mais as coisas.
                insertLegend(castedArray, tableElement.attr('id'), 'bar');

                return [{row: initialSelectionRow, column: 1}];
            },

            createTooltip: createTooltipBar,

            drawChart: function (datatable, chart, gridContainer, config={}) {
                // Desenha o gráfico:
                var referenceLines = config.showReferenceLines ? getReferenceLinesConfig(datatable) : {vAxis:{ticks:[]}};
                // Define de forma proporcional a altura do gridContainer
                gridContainer.css('height', proportionalHeight(gridContainer.width(), BAR_ASPECT_RATIO));
                // Coloca na tela!
                chart.draw(datatable, $.extend(true, BAR_OPTIONS, referenceLines,
                    {
                        tooltip: {
                            textStyle: {
                                fontSize: getTooltipFontSize(gridContainer)
                            }
                        }
                    }));
            },

            setSelection: function (chart, category) {
                chart.setSelection([{row: category, column: 1}]);
            }

        },

        hAxisLabeled: {

            visualizationName: 'ColumnChart',

            createDataStructure: function (datatable, table) {

                var tableElement = table.element,
                    tbodyArray = tbodyToArray(tableElement),
                    castedArray,
                    initialSelectionRow,
                    initialSelection = table.options.initialSelection;

                // Processa os dados obtidos da tabela,
                // mantendo apenas as colunas especificadas,
                // e transformando de string para o tipo especificado.
                castedArray = castTable(tbodyArray, ['string', 'number']);
                castedArray = pushColumn(castedArray, BAR_COLORS_DEFAULT);
                castedArray = pushColumn(castedArray, createTooltip(tbodyArray, table));

                // Dada uma coluna, obtém a linha que possui o valor maior
                initialSelectionRow = (function () {
                    return (initialSelection === INITIAL_SELECTION.ultimo) ? castedArray.length - 1: getMaxValueIndex(getColumn(castedArray, 1));
                })();

                // Insere os dados no datatable que dará origem ao gráfico
                datatable.addColumn({type: 'string', label: 'Rótulo'});
                datatable.addColumn({type: 'number', label: 'Valor'});
                datatable.addColumn({type: 'string', role: 'style'});
                datatable.addColumn({type: 'string', role: 'tooltip', p: {html: false}});
                datatable.addRows(castedArray);

                return [{row: initialSelectionRow, column: 1}];
            },

            createTooltip: createTooltipBar,

            drawChart: function (datatable, chart, gridContainer, config={}) {

                var gridContainerWidth = gridContainer.width(),
                    options;
                var referenceLines = config.showReferenceLines ? getReferenceLinesConfig(datatable) : {};

                options = $.extend(true, {}, H_AXIS_LABELED_OPTIONS, 
                    configureHorizontalAxis(datatable, gridContainerWidth), 
                    referenceLines);

                // Desenha o gráfico:
                // Define de forma proporcional a altura do gridContainer
                gridContainer.css('height', proportionalHeight(gridContainerWidth, BAR_ASPECT_RATIO));
                // Coloca na tela!
                chart.draw(datatable, $.extend(true, options,
                    {
                        tooltip: {
                            textStyle: {
                                fontSize: getTooltipFontSize(gridContainer)
                            }
                        }
                    }));

            }
        },

        hAxisLabeledLimit: {

            visualizationName: 'ComboChart',

            createDataStructure: function (datatable, table) {

                var tableElement = table.element,
                    tbodyArray = tbodyToArray(tableElement),
                    castedArray,
                    initialSelectionRow,
                    initialSelection = table.options.initialSelection;

                // Processa os dados obtidos da tabela,
                // mantendo apenas as colunas especificadas,
                // e transformando de string para o tipo especificado.
                // Rótulo (pontos no eixo horizontal)
                castedArray = castTable(tbodyArray, ['string']);
                // Valor apresentado no gráfico de barras
                castedArray = pushColumn(castedArray, getColumn(castTable(tbodyArray, ['', 'number']), 0));
                // Tooltip: ligado ao gráfico de barras (mas que mostra informação também sobre o limite)
                castedArray = pushColumn(castedArray, createTooltip(tbodyArray, table));
                // Cores para o gráfico de barras.
                castedArray = pushColumn(castedArray, BAR_COLORS_DEFAULT);
                // Valor para o gráfico de linhas (limite)
                castedArray = pushColumn(castedArray, getColumn(castTable(tbodyArray, ['','','','number']), 0));
                // Anotação para o gráfico de linhas
                castedArray = pushColumn(castedArray, ['Limite']);

                // Dada uma coluna, obtém a linha que possui o valor maior
                initialSelectionRow = (function () {
                    return (initialSelection === INITIAL_SELECTION.ultimo) ? castedArray.length - 1: getMaxValueIndex(getColumn(castedArray, 1));
                })();

                // Insere os dados no datatable que dará origem ao gráfico
                // Visão geral da relação entre dados e roles aqui: https://developers.google.com/chart/interactive/docs/roles#role-hierarchy-and-associativity
                datatable.addColumn({type: 'string', label: 'Rótulo'});
                datatable.addColumn({type: 'number', label: 'Valor'});
                datatable.addColumn({type: 'string', role: 'tooltip', p: {html: false}});
                datatable.addColumn({type: 'string', role: 'style'});
                datatable.addColumn({type: 'number', label: 'Limite'});
                datatable.addColumn({type: 'string', role: 'annotation'});
                datatable.addRows(castedArray);

                return [{row: initialSelectionRow, column: 1}];

            },

            createTooltip: function (tbodyArray, table) {

                var column;

                column = $.map(tbodyArray, function (row, index) {

                    var label,
                        tooltipContent;

                    label = ellipsisLabel(row[0], table);

                    tooltipContent =
                        `<span class="chart-tooltip__label">${label}</span>
                        <span class="chart-tooltip__value">${row[1]}</span>
                        <span class="chart-tooltip__percent-limit">(${row[2]} do limite)</span>`;

                    return formatTooltipNonHTML(tooltipContent, index);
                });

                return column;
            },

            drawChart: function (datatable, chart, gridContainer, config={}) {

                var gridContainerWidth = gridContainer.width(),
                    options;
                var referenceLines = config.showReferenceLines ? getReferenceLinesConfig(datatable) : {};                

                options = $.extend(true, {}, H_AXIS_LABELED_OPTIONS, LIMIT_OPTIONS, 
                    configureHorizontalAxis(datatable, gridContainerWidth), 
                    referenceLines);

                // Desenha o gráfico:
                // Define de forma proporcional a altura do gridContainer
                gridContainer.css('height', proportionalHeight(gridContainerWidth, BAR_ASPECT_RATIO));
                // Coloca na tela!
                chart.draw(datatable, $.extend(true, options,
                    {
                        tooltip: {
                            textStyle: {
                                fontSize: getTooltipFontSize(gridContainer)
                            }
                        }
                    }));
            }

        },

        treeMap : {

            visualizationName: 'TreeMap',

            // Uma variável global (de instância) do treeMap
            quantityName: undefined,

            createDataStructure: function (datatable, table) {

                var tableElement = table.element,
                    tbodyArray = tbodyToArray(tableElement),
                    castedArray = [],
                    rootId = table.element.find('th').eq(0).text(),
                    labelColumn,
                    sizeColumn,
                    parentColumn = [],
                    colorColumn = [];

                // Rótulo que dá nome às quantidades mostradas no gráfico.
                charts.treeMap.quantityName = table.element.find('th').eq(1).text();

                /*
                 * Nesse trecho, cria-se a estrutura para montar os nós do gráfico,
                 * correspondentes a cada linha de dado da tabela original.
                 * O nó "root" é uma exceção e é tratado depois.
                 */
                // A formação do array necessário para o gráfico parte dos rótulos
                // presentes na primeira coluna da tabela original.
                // Cada rótulo identifica um nó no gráfico.
                // castedArray = castTable(tbodyArray, ['string']);
                labelColumn = getColumn(castTable(tbodyArray, ['string']), 0);

                // Na segunda coluna da tabela original, encontram-se os valores
                // que vão representar o tamanhoo de cada nó.
                sizeColumn = getColumn(castTable(tbodyArray, ['','number']), 0);

                // Para cada linha da tabela (cada rótulo de nó), nas colunas parent e color
                // são criadas linhas correspondentes.
                // - parent vai apontar para o nó "Root".
                // - color é um valor sequencial.
                $.each(labelColumn, function (index) {
                    parentColumn.push(rootId);
                    colorColumn.push(index);
                });

                /*
                 * Nesse trecho, cria-se a linha correspondente ao nó "root"
                 */
                // Acrescentando, ao início das colunas, os valores para o nó root.
                labelColumn.unshift(rootId);
                sizeColumn.unshift(null);
                parentColumn.unshift(null);
                colorColumn.unshift(null);


                // Agora, unindo tudo em castedArray
                $.each(labelColumn, function (index) {
                    // castedArray é nada mais que a união das 'colunas' em um array bidimensional.
                    castedArray[index] =
                        [
                            labelColumn[index],
                            parentColumn[index],
                            sizeColumn[index],
                            colorColumn[index]
                        ];
                });

                // Colunas de acordo com a especificação do Treemap
                datatable.addColumn({type: 'string', label: 'Rótulo'});
                datatable.addColumn({type: 'string', label: 'Pai'});
                datatable.addColumn({type: 'number', label: 'Tamanho'});
                datatable.addColumn({type: 'number', label: 'Cor'});
                datatable.addRows(castedArray);

                // Sempre indica que o nó root deve ser selecionado (o que equivale a nenhuma seleção).
                return [{row:0}];

            },

            drawChart: function (datatable, chart, gridContainer) {
                var tooltipElements,
                    options;

                // Desenha o gráfico:
                // Define de forma proporcional a altura do gridContainer
                gridContainer.css('height', proportionalHeight(gridContainer.width(), BAR_ASPECT_RATIO));

                var createTooltip = function (row, size, value) {

                    var tooltip;

                    tooltip =
                        `<div class="${TREEMAP_TOOLTIP_CLASS} treemap-tooltip">
                            <div class="google-visualization-tooltip">
                                <div class="chart-tooltip">
                                    <div class="chart-tooltip__content">
                                        <div class="chart-tooltip__label">
                                            ${datatable.getValue(row, 0)}
                                        </div>
                                        <div class="chart-tooltip__value">
                                            ${charts.treeMap.quantityName}: ${size}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>`;

                    return tooltip;
                };

                options = $.extend(true, {}, TREEMAP_OPTIONS, {
                    generateTooltip: createTooltip
                });

                // Coloca na tela!
                chart.draw(datatable, options,
                    {
                        tooltip: {
                            textStyle: {
                                fontSize: getTooltipFontSize(gridContainer)
                            }
                        }
                    });

                // Ajustes do tooltip (para superar conflito com os estilos do mmenu)
                tooltipElements = $(`body > div > .${TREEMAP_TOOLTIP_CLASS}`).not(`.${TREEMAP_TOOLTIP_CLASS}--indexed`);
                tooltipElements.addClass(`${TREEMAP_TOOLTIP_CLASS}--indexed`);
                tooltipElements.parent().css('z-index', 1);

            },

        }
    };

    function drawChart (datatable, chart, gridContainer, type, options) {

        var drawingFunction = charts[type].drawChart;

        // Desenha a tabela uma vez.
        drawingFunction(datatable, chart, gridContainer, options);
        // Programa a função a função para ser chamada novamente quando necessário
        createResizeHandler(drawingFunction, datatable, chart, gridContainer, options);
        createShownTabHandler(drawingFunction, datatable, chart, gridContainer);
    }


    function createChart (table, type) {

        var datatable,
            chart,
            referenceId = table.element.attr('id'),
            gridContainer = createGridContainer(referenceId, type),
            chartName = charts[type].visualizationName,
            currentSelecion = false,
            initialSelection;

        // Adiciona a largura do container para alcançar profundidade no código
        table.gridContainerWidth = gridContainer.width();

        // Instancia os objetos do google: datatable e chart
        datatable = new google.visualization.DataTable();
        chart = new google.visualization[chartName](gridContainer.get(0));

        // Cria a estrutura de dados necessária para o gráfico.
        initialSelection = charts[type].createDataStructure(datatable, table);

        // -- EVENTOS

        // Faz com que um tooltip inicie já mostrado para o usuário, ao renderizar o gráfico
        google.visualization.events.addListener(chart, 'ready', function select() {

            if (currentSelecion === false) {
                // No redimensionamento do gráfico, pode ser que o usuário já tenha alterado a seleção.
                // Por padrão (caso o usuário não tenha alterado a seleção),
                // seta a seleção atual como o que foi definido na criação da estrutura do gráfico.
                currentSelecion = initialSelection;
            }
            // Por algum motivo que não entendi bem, a rótulo da seleção inicial fica ligeiramente cortado à direita
            // Para tentar retardar um pouco sua renderização, o seguinte atraso foi inserido
            setTimeout( function() {
                chart.setSelection(currentSelecion);
            }, 500);
        });

        // Ajustes de acessibilidade
        google.visualization.events.addListener(chart, 'ready', function select() {

            // Mesmo no evento 'ready', a tabela acessível, criada automaticamente pela biblioteca de gráficos,
            // não está disponível. Esse setTimeout adia a execução da função para que essa tabela
            // já tenha sido criada quando precisamos dela.
            setTimeout(function () {

                var visualChart = gridContainer.find('[aria-label^="Um gráfico"]'),
                    tableFromChart = visualChart.filter('svg').next('div');

                // Melhora o aria-label padrão fornecido pela biblioteca
                visualChart.attr('aria-label', 'Gráfico representando a tabela anterior');
                // Esconde dos leitores de tela a tabela criada automaticamente pela biblioteca.
                tableFromChart.attr('aria-hidden', true);
                // Esconde todos os textos "plotados" no gráfico (anotações e rótulos dos eixos, mas não o tooltip).
                gridContainer.find('svg text').attr('aria-hidden', true);

            }, 0);
        });

        if (type === 'treeMap') {
            // Desabilita o "drilldown", fazendo simplesmente com que a seleção seja ignorada.
            google.visualization.events.addListener(chart, 'select', function avoidSelection() {
                chart.setSelection([]);
            });
        }
        else {
            // Ao alterar a seleção, salva a seleção feita para usar na próxima renderização.
            google.visualization.events.addListener(chart, 'select', function saveSelection() {
                currentSelecion = chart.getSelection();
            });
        }

        // Programa o botão fechar do tooltip
        gridContainer.on('click', '.js-chart-tooltip-close', function (e) {
            e.preventDefault();
            chart.setSelection([]);
            currentSelecion = [];
        });

        // Acionamento de um item da legenda
        $(`#${referenceId}-chart`).on('click', '.js-open-tooltip', function (e) {
            var category = $(this).data('category');
            e.preventDefault();
            charts[type].setSelection(chart, category);
        });

        // return [{row: greatestValueRow, column: 1}];

        // -- CRIAÇÃO EFETIVA DO GRÁFICO

        // Cria o gráfico propriamente dito
        drawChart(datatable, chart, gridContainer, type, table.options);
    }

    /*
     * Verifica, de acordo com a(s) classe(s) informada(s) para a tabela,
     * que tipo de gráfico deve ser construído.
     */
    function getChartType (table) {

        var type;

        // Gráfico de barras
        if (table.hasClass('js-chart--bar')) {

            type = 'bar';
        }
        // Gráfico com rótulos no eixo horizontal
        else if (table.hasClass('js-chart--h-axis-labeled')) {
            // Gráfico com rótulos no eixo horizontal com limite
            if (table.hasClass('js-chart--limit')) {
                type = 'hAxisLabeledLimit';
            }
            // Gráfico com rótulos no eixo horizontal básico
            else {
                type = 'hAxisLabeled';
            }
        }
        else if (table.hasClass('js-chart--treemap')) {

            type = 'treeMap';
        }
        // Por padrão (se não tiver informado modificadores), trata como gráfico de pizza.
        else {
            type = 'pie';
        }
        return type;
    }

    $.fn.table2Chart = function (options) {

        var defaultOptions = {
            tooltip: undefined,
            showReferenceLines: true,
            tooltipLengthReference: 50,
            initialSelection: null
        };
        // Estende as opções default com as opções informadas
        options = $.extend({}, defaultOptions, options);

        // Solicita a carga do pacote de geração de gráficos
        google.charts.load('current', {packages: ['corechart', 'treemap'], language: 'pt-BR'});

        return this.each(function () {

            var table = $(this),
                type;

            table = {
                element: table,
                options: options
            };

            // Obtém o tipo de gráfico de acordo com a tabela.
            type = getChartType(table.element);

            table.type = type;

            // Verifica se a tabela atende aos critérios, abortando se não atender.
            if (checkTable(table.element, CHARTCRITERIA)) {

                google.charts.setOnLoadCallback(function () {
                    createChart(table, type);
                });
                table.element.wrap('<div class="sr-only"></div>');
            } else {
                renderNoData(table);
            }    
        
        });
    };

})(jQuery);