/*
 * Cria objetos globais livres de jquery para uso pelo javascript das páginas
 * cada modulo deve ser criado por sua função específica
 */
(function adicionarControleResponsivo(portalcdLib) {
    var LARGE_SCREEN = 'large_screen',
        SMALL_SCREEN = 'small_screen';
    var renderedState;
    var getCurrentViewWidth = () => window.innerWidth ? window.innerWidth : document.documentElement.offsetWidth;

    var gerenciarBreakpointLG = (callbackMenor, callbackMaior) => {
        const transformBreakpoint = global.RESPONSIVE.MIN.LG; // Transformações para telas grandes em diante

        $(window).resize(() => {
            var offsetWidth = getCurrentViewWidth();
            if (offsetWidth >= transformBreakpoint && renderedState !== LARGE_SCREEN) {
                callbackMaior();
                renderedState = LARGE_SCREEN;
            } else {
                if (offsetWidth < transformBreakpoint && renderedState !== SMALL_SCREEN) {
                    callbackMenor();
                    renderedState = SMALL_SCREEN;
                }
            }
        });
        $(window).resize();
    };
    portalcdLib.RESPONSIVIDADE = {};
    portalcdLib.RESPONSIVIDADE.monitorarLG = gerenciarBreakpointLG;
})( window.PORTALCD = window.PORTALCD || {} );

(function adicionarApiMensagensValidacao(portalcdLib) {
    const _matches = function(ele, selector) {
        return (
            ele.matches || 
            ele.matchesSelector || 
            ele.msMatchesSelector || 
            ele.mozMatchesSelector || 
            ele.webkitMatchesSelector || 
            ele.oMatchesSelector
        ).call(ele, selector);
    };    
    const _isPresent = function (elemSelector = '') {
        return !(elemSelector === null || elemSelector.length === 0);
    };
    const _checkValidElements = function (elem) {
        if (!_isPresent(elem)) {
            throw new Error('Elemento não encontrado.');
        }
        let elementosValidos = ['INPUT', 'SELECT', 'TEXTAREA', 'FIELDSET', 'FORM'];
        let tiposInputValidos = ['TEXT', 'DATE', 'NUMBER', ''];
        let tipoElemento = elem.tagName;
        let tipoInput = elem.getAttribute('type') || '';

        if (!elementosValidos.includes(tipoElemento)) {
            throw new Error('Tipo de elemento não aceito.');
        }
        if (tipoElemento === 'INPUT' && !tiposInputValidos.includes(tipoInput.toUpperCase())) {
            throw new Error('Tipo de input não aceito.');
        }
        return elem;
    };
    const mostrarErro = (seletorElementoInvalido, mensagem = 'ocorreu um erro', referenciaInsercao) => {
        let elementoInvalido = _checkValidElements(document.querySelector(seletorElementoInvalido));
        let elementoAnterior;
        if (_isPresent(referenciaInsercao)) {
            elementoAnterior = document.querySelector(referenciaInsercao);
        }
        
        let tipoInsercao = (elementoAnterior) ? 'PONTO_DESIGNADO' : elementoInvalido.tagName;
        let idMensagem = 'mensagem-' + Math.floor(Math.random()*100000);
        let elementoMensagem = document.createElement('div');
        elementoMensagem.setAttribute('id', idMensagem);
        elementoMensagem.classList.add('g-l-message');
        elementoMensagem.innerHTML = `<span class="g-message g-message--invalid">${mensagem}</span>`;

        elementoInvalido.setAttribute('aria-invalid','true');
        elementoInvalido.setAttribute('aria-describedby', idMensagem);

        const inserirMensagem = {
            FIELDSET: function() {
                return elementoInvalido.querySelector('legend').insertAdjacentElement('afterend',elementoMensagem);
            },
            FORM: function() {
                return elementoInvalido.insertBefore(elementoMensagem, elementoInvalido.firstChild);
            },
            INPUT: function() {
                let ponto;

                if (elementoInvalido.parentNode.classList.contains('input-group')) {
                    ponto = elementoInvalido.parentNode;
                } else {
                    ponto = elementoInvalido;
                }
    
                return ponto.insertAdjacentElement('afterend',elementoMensagem);
            },
            SELECT: function()  {
                return this['INPUT']();
            },
            TEXTAREA: function() {
                return this['INPUT']();
            },
            PONTO_DESIGNADO: function() {
                return elementoAnterior.insertAdjacentElement('afterend',elementoMensagem);
            }
        };

        inserirMensagem[tipoInsercao]();

        return document.querySelector('#' + idMensagem);

    };
    const limparErro = (seletorElementoInvalido) => {
        let elementoInvalido = document.querySelector(seletorElementoInvalido);
        let elementoMensagemId = elementoInvalido.getAttribute('aria-describedby');
        let elementoMensagem = document.querySelector('#' + elementoMensagemId);

        elementoInvalido.removeAttribute('aria-invalid');
        elementoInvalido.removeAttribute('aria-describedby');

        try { 
            elementoMensagem.parentNode.removeChild(elementoMensagem);
        } catch(e) { return void(0);}

        return elementoInvalido;
    };

    portalcdLib.MENSAGENS = portalcdLib.MENSAGENS || {};
    portalcdLib.MENSAGENS.mostrarErro = mostrarErro;
    portalcdLib.MENSAGENS.limparErro = limparErro;

})( window.PORTALCD = window.PORTALCD || {} );
