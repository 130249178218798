/**
 * monthPicker
 * Converte um fieldset composto de um input[type=number] e um select de meses com 13 entradas (0=não selecionado)
 * ...em um campo de entrada simples input[type=text], um botão trigger para abrir um calendário apenas de meses 
 * ...que também é adicionado
 * Marcação HTML:
 * - Um fieldset com um atributo id válido e único
 * - Um campo de entrada do tipo input[type=number] como filho
 * - Um campo de entrada do tipo select, com treze filhos option, com valores de 0 a 12
 *   - a entrada de mês 0 corresponde a valor não selecionado
 *
 * Inicialização (deve ser eralizada no js da página):
 * $('my-fieldset-selector').monthPicker();
 *
 *
 */
(
    function ($) {

        var defaults = {
            monthsLabels: ['janeiro','fevereiro','março','abril','maio','junho','julho','agosto','setembro','outubro','novembro','dezembro'],
            min: {
                month: undefined,
                year: undefined
            },
            max: {
                month: undefined,
                year: undefined
            }
        };
        var generalOptions;  
        var a11yLog;
        var popoverTemplate = '<div class="popover" role="tooltip"><div class="arrow"></div><span class="popover-body"></span></div>';

        function createA11yLog() {
            if (!$('.month-picker__a11y-log[role=log]').length) {
                a11yLog = $('<div class="month-picker__a11y-log sr-only" role="status"></div>').appendTo('body');
            }
        }
        function isvalidMarkup(element) {

            var validValues = [0,1,2,3,4,5,6,7,8,9,10,11,12];
            var outOfSequence = false;

            if (!element.is('fieldset')) return false; 
            if (!(element.find('select option').length == 13)) return false;

            element.find('select option').each( function(idx, item) {
                if( $(item).attr('value') != validValues[idx] ) {
                    outOfSequence = true;
                    return;
                }
            });
            if (outOfSequence) return false;
            if (!element.find('input[type=number]').length) return false;

            return true;
        }
        function isPositiveInteger(str) {
            return /^(0*[1-9]\d*)$/.test(str);
        }
        function isValidMonth(str) {
            return /^(0?[1-9]|1[0-2])$/.test(str);
        }
        function isWithinLimits(year, month, limits={}) { 
            return true; //! TEMPORÁRIO: retirar e descomentar a seguir para habilitar limites
            // // verifica por atendimento aos limites para determinar estado do campo proxy
            // limits = $.extend({},generalOptions,limits);
            // var comparableOriginalValue = ('000' + year).substr(-4) + ('0' + month).substr(-2);

            // if (limits.min.year && comparableOriginalValue != '000000') {
            //     var comparableMinValue = ('000' + limits.min.year).substr(-4) + ('0' + limits.min.month).substr(-2);
            //     if (comparableOriginalValue < comparableMinValue) {
            //         return false;
            //     }
            // }
            // if (limits.max.year && comparableOriginalValue != '000000') {
            //     var comparableMaxValue =('000' + limits.max.year).substr(-4) + ('0' + limits.max.month).substr(-2);
            //     if (comparableOriginalValue > comparableMaxValue) {
            //         return false;
            //     }
            // }
            // return true;
        }
        function getRandomId(prefix) {
            prefix = prefix || 'id';
            return `${prefix}_${parseInt(Math.random() * 1000000)}`;
        }
        function logMessage(msg) {
            a11yLog.empty().append(msg);
        }
        function Publisher () {
            this._observerFunctions = [];
          
            this.subscribe = function (observerFunction) {
              this._observerFunctions.push(observerFunction);
            };
          
            this.unsubscribe = function (observerFunction) {
              this._observerFunctions = this._observerFunctions.filter(obs => observerFunction !== obs);
            };
          
            this.fire = function (change, data) {
              this._observerFunctions.forEach(observerFunction => {
                observerFunction(change, data);
              });
            };
        }
        function MonthCalendar (idToUse, limits) {

            var VIEW_STATE = {
                open:1,
                closed:2
            };
            var today = new Date();
            // Private properties
            var thisCalendar = this;
            var _state = VIEW_STATE.close;
            var _calendarYear = today.getFullYear();
            var _selectedMonth = today.getMonth();
            var _selectedYear = _calendarYear;
            var _currentFocusedMonth = 0;
            var _element = _buildView(_calendarYear);
            var _nextButton = _element.find('.month-picker__next');
            var _prevButton = _element.find('.month-picker__prev');
            var _headerYear = _element.find('.month-picker__year');
            var _bodyMonths = _element.find('.month-picker__month');
            var _firstFocusable = _prevButton;
            var _lastFocusable;
            var _dropdownAlignment = 'right'; // 'left' | 'right' | 'center'
            var _publisher = new Publisher();

            // Private methods
            function _buildView(year) {
                var viewId = getRandomId();
                var dropdownContainer = $(`<div id=${idToUse} role="dialog" aria-labelledby="${viewId}">`)
                    .addClass('month-picker__dropdown-container');
                var dropdownHeader = $(`<div aria-live="polite"><span class="month-picker__year" id=${viewId}>${year}</span></div>`)
                    .addClass('month-picker__dropdown-header');
                var dropdownNext = $('<button type="button" class="month-picker__next" aria-label="ano posterior"></button>')
                    .append($('<span aria-hidden="true"><i class="fas fa-chevron-right"></i></span>'));
                var dropdownPrevious = $('<button type="button" class="month-picker__prev" aria-label="ano anterior"></button>')
                    .append($('<span aria-hidden="true"><i class="fas fa-chevron-left"></i></span>'));
                var dropdownBody= $('<div role="listbox" aria-expanded="true" aria-label="meses">').addClass('month-picker__dropdown-body');
    
                dropdownHeader.prepend(dropdownPrevious);
                dropdownHeader.append(dropdownNext);
                dropdownContainer.append(dropdownHeader);

                //! INVESTIGAR: Esta seção não consegue fazer o tooltip aparecer somente para navegação desabilitada
                // dropdownNext.attr('data-toggle','popover');
                // dropdownNext.popover({
                //     'container': false,
                //     'template': popoverTemplate,
                //     'trigger': 'manual',
                //     'content': 'ano posterior fora dos limites permitidos'
                // });
                // dropdownPrevious.attr('data-toggle','popover');
                // dropdownPrevious.popover({
                //     'container': false,
                //     'template': popoverTemplate,
                //     'trigger': 'manual',
                //     'content': 'ano anterior fora dos limites permitidos'
                // });
                defaults.monthsLabels.map(  (item, idx) => {
                    var newMonth = $('<button type="button" role="option" tabindex="-1"></button>')
                                .addClass('month-picker__month').text(item);
                    dropdownBody.append(newMonth);

                    newMonth.attr('data-toggle','popover');
                    newMonth.popover({
                        'template': popoverTemplate,
                        'trigger': 'click',
                        'content': 'mês fora dos limites permitidos'
                    });
                });
                dropdownContainer.append(dropdownBody);

                return dropdownContainer;
            }
            function _setCalendarState(calendarYear) {
                var year = _selectedYear;
                var month = _selectedMonth;
                _calendarYear = calendarYear;
    
                _headerYear.text(calendarYear); 
                _bodyMonths.removeClass(['isCurrent','isSelected', 'isForbidden'])
                    .attr('aria-label',null);
                _bodyMonths.each( function(idx, item) {
                    item = $(item);
                    if ( today.getFullYear() == calendarYear && today.getMonth()  == idx ) {
                        item.addClass('isCurrent');
                    }
                    if ( year == calendarYear && month - 1 == idx ) {
                        item
                            .addClass('isSelected');
                        _currentFocusedMonth = idx + 1;
                    } 
                    if (!isWithinLimits(calendarYear, idx + 1, limits)) {
                        item.addClass('isForbidden')
                            .attr('aria-label','mês fora dos limites permitidos');
                    }
                });
            }
            function _setMonthFocus() {
                if (_currentFocusedMonth < 1) {
                    _currentFocusedMonth = 12 + _currentFocusedMonth;
                } else if (_currentFocusedMonth > 12) {
                    _currentFocusedMonth = 0 + (_currentFocusedMonth - 12);
                }
                _bodyMonths.removeClass('isFocused').attr('tabindex','-1');
                _lastFocusable = $(_bodyMonths[_currentFocusedMonth - 1])
                                    .addClass('isFocused')
                                    .attr('tabindex','0')
                                    .trigger('focus');
            }

            // Public interface

            // Set the best alignment for a box relative to a context container from
            // a horizontal position (right is preferable, then center and finally left)
            this.setAlignment = function (whereFrom, boxWidth, contextContainer) {
                contextContainer = contextContainer || document.querySelector('body');
                var containerLeftOffset = $(contextContainer).offset().left;
                var containerRightOffset = containerLeftOffset + $(contextContainer).width();
                _dropdownAlignment = 'right';

                if (whereFrom + boxWidth > containerRightOffset) {
                    _dropdownAlignment = (whereFrom + boxWidth/2 > containerRightOffset) 
                        ? _dropdownAlignment = 'left'
                        : _dropdownAlignment = 'cwnter';
                }

            };
            this.setAriaStates = function (states) {
                var targetElement = _element;
                for (var state in states) {
                    targetElement.attr(`aria-${state}`, states[state]);                }
            };
            this.getSelected = function () {
                return {
                    year: _selectedYear,
                    month: _selectedMonth.toString()
                };
            };
            this.element = function () {
                return _element;
            };
            this.open = function (highlightedYear = 0, highlightedMonth = 0) {
                // should always open in the current input field month 
                _selectedYear = isPositiveInteger(highlightedYear) ? highlightedYear : 0;
                _selectedMonth = isValidMonth(highlightedMonth) ? highlightedMonth : 0;                
                _calendarYear = _selectedYear || new Date().getFullYear();
                _currentFocusedMonth = _selectedMonth || new Date().getMonth() + 1;

                _element.attr('aria-hidden', 'false');
                _element.removeClass(['is-center-aligned','is-left-aligned','is-right-aligned']);
                _element.addClass(`is-${_dropdownAlignment}-aligned`);
                _element.show();

                _prevButton.attr('tabindex','0');
                _nextButton.attr('tabindex','0');
                _setCalendarState(_calendarYear);
                _setMonthFocus();
                _publisher.fire('open');

                return _state = VIEW_STATE.open;
            };
            this.close = function () {
                // set tabindexes to 1
                _element.find('[tabindex=0]').attr('tabindex','-1');

                _element.attr('aria-hidden', 'true');
                _element.hide();

                _state = VIEW_STATE.closed;
                _element.closest('.month-picker__container')
                        .find('.month-picker__trigger-button').trigger('focus');
                _publisher.fire('close');
                return thisCalendar.getSelected();
            };
            this.toggle = function () {
                return _state == VIEW_STATE.open ? this.close() : this.open();
            };
            this.isOpen = function () {
                return _state == VIEW_STATE.open;
            };
            this.getPublisher = function() {
                return _publisher;
            };

            // Interaction reaction
            _nextButton.on('click', function(evt) {
                evt.preventDefault();

                var nextYear = parseInt(_calendarYear) + 1;
                if (isWithinLimits(nextYear, 1, limits)) {
                    _setCalendarState(nextYear);
                } else {
                    logMessage('Ano posterior fora dos limites permitidos');
                    $(evt.target).popover('show'); //! INVESTIGAR: estranhamente é acionado o tooltip mesmo quando o ano é válido
                }
            });
            _prevButton.on('click', function(evt) {
                evt.preventDefault();

                var previousYear = parseInt(_calendarYear) - 1;
                if (isWithinLimits(previousYear, 12, limits)) {
                    _setCalendarState(previousYear);
                } else {
                    logMessage('Ano anterior fora dos limites permitidos');
                    $(evt.target).popover('show'); //! INVESTIGAR: estranhamente é acionado o tooltip mesmo quando o ano é válido
                }
            });
            _bodyMonths.on('click', function(evt) {
                evt.preventDefault();

                var target = $(evt.currentTarget); 
                var month = target.text();
                var year = _headerYear.text();

                if (target.not('.isForbidden').length) {
                    _selectedMonth = defaults.monthsLabels.indexOf(month) + 1;
                    _selectedYear = year;

                    _bodyMonths.find('[aria-selected]').attr('aria-selected',null);
                    target.attr('aria-selected','true');
                    logMessage(`Mês ${_selectedMonth} de ${_selectedYear} selecionado`);
                    // Emit a signal to broadcast the new selected month/year
                    _publisher.fire('select', thisCalendar.getSelected());
                } else {
                    target.popover('toggle');
                }
            });
            _element.on('keydown', function (evt) {
                var pressedKey = evt.key;

                var actions = {
                    'ArrowLeft': function() {
                        _currentFocusedMonth != 1 && (_currentFocusedMonth = _currentFocusedMonth - 1);
                        return true;
                    },
                    'ArrowUp': function() {
                        return this['ArrowLeft']();
                    },
                    'ArrowRight': function() {
                        _currentFocusedMonth != 12 && (_currentFocusedMonth = _currentFocusedMonth + 1);
                        return true;
                    },
                    'ArrowDown': function() {
                        return this['ArrowRight']();
                    },
                    'Tab': function() {
                        
                        if (_lastFocusable.is(':focus') && !evt.shiftKey) {
                            evt.preventDefault();
                            _firstFocusable.trigger('focus');
                        } else if (_firstFocusable.is(':focus')  && evt.shiftKey) {
                            evt.preventDefault();
                            _lastFocusable.trigger('focus');
                        }
                    }
                };
                // Execute o handler somente se a tecla estiver configurada em 'actions'
                actions[pressedKey] && actions[pressedKey]() && _setMonthFocus();

            });

        }
        function Trigger () { 
            var VIEW_STATE = {
                pressed:'PRESSED',
                unpressed:'UNPRESSED'
            };
            // Private properties
            var thisTrigger = this;
            var _state = VIEW_STATE.unpressed;
            var _element = _buildView();
            var _triggerButton = _element.find('.month-picker__trigger-button');
            var _publisher = new Publisher();

            function _buildView() {
                var triggerIconMarkup = '<i class="far fa-calendar-alt"></i>';
                var view = $(`
                    <div class="input-group-addon month-picker__trigger">
                        <a href="#" role="button" class="input-group-text month-picker__trigger-button">
                            ${triggerIconMarkup}
                        </a>
                    </div>
                    `);
    
                return view;
            }

            this.element = function () {
                return _element;
            };
            this.setState = function (newState) {
                if (VIEW_STATE[newState.toLowerCase()]) {
                    _element.find('[role=button]').attr('aria-pressed', newState===VIEW_STATE.pressed ? 'true' : 'false');
                    _state = newState;
                }
            };
            this.getState = function () {
                return _state;
            };
            this.getButton = function () {
                return _triggerButton;
            };
            this.setAriaStates = function (states) {
                var targetElement = _element.find('.month-picker__trigger-button');
                for (var state in states) {
                    targetElement.attr(`aria-${state}`, states[state]);
                }
            };
            this.getPublisher = function() {
                return _publisher;
            };
            this.toggleState = function() {
                thisTrigger.setState(_state === VIEW_STATE.pressed ? VIEW_STATE.unpressed : VIEW_STATE.pressed );
            };

            _triggerButton.on('click', function(evt) {
                evt.preventDefault();
                
                thisTrigger.toggleState();
                _publisher.fire(_state);
            });
        }
        function InputProxy (original) {
            var thisProxy = this;

            var _idOriginalElement = original.attr('id');
            var _originalMonthFormField = original.find('select').addClass('month-picker__original-month');
            var _originalYearFormField = original.find('input[type=number]').addClass('month-picker__original-year'); 
            var _originalYear = _originalYearFormField.val() || 0;
            var _originalMonth = _originalMonthFormField.find('option[selected]').attr('value') || 0;
            var _element= $(`<input type="text" id="${_idOriginalElement}__proxy" class="month-picker__proxy" autocomplete="off">`);
            var _minInputYear = _originalYearFormField.attr('min') || undefined;
            var _maxInputYear = _originalYearFormField.attr('max') || undefined;
            var _limits = function () {
                var limits = {};
                if ((generalOptions.min.year && generalOptions.min.month) || (generalOptions.max.year && generalOptions.max.month)) {
                    limits.min = {
                        year: generalOptions.min.year,
                        month: generalOptions.min.month
                    };
                    limits.max = {
                        year: generalOptions.max.year,
                        month: generalOptions.max.month
                    };
                    return limits;
                }

                if (_minInputYear) {
                    limits.min = {
                        year: _minInputYear,
                        month: 1
                    };
                }
                if (_maxInputYear) {
                    limits.max = {
                        year: _maxInputYear,
                        month: 12
                    };
                }
                return limits;
            }();

            this.element = function () {
                return _element;
            };
            this.update = function(year, month) {
                var formattedValue = _getFormattedProxyValue(year, month);
                year = formattedValue && year;
                month = formattedValue && month;

                // atualiza o valor do proxy
                _element.val(formattedValue);

                // guarda o estado do ano no DOM
                var yearInput = original.find('.month-picker__original-year').attr('value',year||0);

                // guarda o estado do mês no DOM
                original.find('.month-picker__original-month option[selected]').attr('selected', null);
                original.find(`.month-picker__original-month option[value=${month||0}]`).attr('selected', 'selected');

                // guarda o estado no próprio objeto
                _originalYear = yearInput.attr('value');
                _originalMonth = original.find('.month-picker__original-month option[selected]').attr('value') || 0;

                _element.removeClass('is-invalid');
                if (!isWithinLimits(_originalYear, _originalMonth, _limits)) {
                    _element.addClass('is-invalid');
                }
            };
            this.getValue = function () {
                return { 
                    year: _originalYear,
                    month: _originalMonth
                };
            };
            this.getLimits = function () {
                return _limits;
            };
    
            function _getFormattedProxyValue(year, month) {
                year = $.trim(year);
                month = $.trim(month);
                if (isPositiveInteger(year) && isValidMonth(month)) {
                    return `${('0' + month).slice(-2)}/${year}`;
                }
                return '';
            }

            // ALterar o label do campo que apontava para o fieldset para faxê-lo apontar para o proxy (a11y)
            $(`label[for=${_idOriginalElement}]`).attr('for',`${_idOriginalElement}__proxy`);

            // guardar os campos originais escondidos da visualização para armazenar o estado
            original.wrap('<div class="month-picker__original-container"></div>').hide();

            // configurar o valor inicial do proxy, para o caso de estar preenchido no momento da aplicação deste plugin
            thisProxy.update(_originalYear, _originalMonth);

            // escutar o evento de perda do foco para aplicar a máscara
            _element.on('blur', function(evt) {
                var valor = evt.target.value;
                var reValorValido = /^[0-9]{1,2}\/?[0-9]{4}$/;

                if (!valor.trim().match(reValorValido)) {
                    thisProxy.update(0,0);
                    return;
                }
                if (valor.indexOf('/') === -1) {
                    valor = valor.substr(0, valor.length -4) + '/' + valor.slice(-4);
                }
                var month = valor.split('/')[0];
                var year = valor.split('/')[1];
                thisProxy.update(year, month);
            });
        }
        $.fn.monthPicker = function(options) {
            options = {}; //!TEMPORÁRIO: retirar para habilitar limites 
            generalOptions = $.extend(true, {}, defaults, options);

            var DOMElements = this;
            var activeTriggerButtons = [];

            createA11yLog();

            return DOMElements.each( function() {

                var element = $(this);

                // validate the mandatory structure
                if ( !isvalidMarkup(element)) {
                    return false;
                }
 
                // create the input proxy, that represents the original input form fields
                var proxy = new InputProxy(element);

                // create the trigger, that show the calendar
                var idCalendar = element.attr('id') + '_' + parseInt(Math.random() * 1000000);
                var thisTrigger = new Trigger();
                thisTrigger.setAriaStates({
                    'label': 'Escolha um mês',
                    'controls': idCalendar
                });

                // cada disparador é guardado para identificação pelo documento
                activeTriggerButtons.push(thisTrigger);

                // create the calendar, that list the months that can be selected
                var thisCalendar = new MonthCalendar(idCalendar, proxy.getLimits());
                thisCalendar.setAriaStates({
                    'modal': 'true'
                });
                
                // create the widget structure and position it before the original fields in DOM order
                var structure = $('<div class="input-group month-picker__container"></div>');
                element.parent().before(structure);
    
                // append the proxy
                structure.append(proxy.element());
                //$(`label[for=${element.attr('íd')}]`).attr('for',element.attr('íd') + '__proxy');

                // append the trigger
                structure.append(thisTrigger.element());

                // append the calendar in the structure and set it closed
                structure.find('.month-picker__trigger').append(thisCalendar.element());
                thisCalendar.close();

                // the document should control the click out of the calendar area and close all open calendars
                $(document).on('mouseup', function (evt) {
                    activeTriggerButtons.map( function (activeTrigger) {
                        if (activeTrigger.getState() === 'PRESSED') {
                            activeTrigger.getButton().trigger('click');
                        } 
                    });
                });
                $(document).on('keydown', function (evt) {
                    var pressedKey = evt.key;
                    if (pressedKey === 'Escape') {
                        activeTriggerButtons.map( function (activeTrigger) {
                            if (activeTrigger.getState() === 'PRESSED') {
                                activeTrigger.getButton().trigger('click');
                            } 
                        });
                    }
                });

                // esse handler cancela a bolha, impedindo o fechamento do calendário se o clique for dentro dele
                $(thisCalendar.element()).on('mouseup', function (evt) {
                    evt.stopPropagation();
                });

                // acting in response of interaction 
                // thisCalendar.getPublisher().subscribe(                
                //     function callOnCalendarStateChange (change) {
                //         var changeButtonState = {
                //             'close': function() {
                //                 thisTrigger.getState() === 'PRESSED' && thisTrigger.setState('UNPRESSED');
                //                 thisTrigger.setAriaStates({'expanded':'false'});
                //             },
                //             'open': function() {
                //                 thisTrigger.getState() === 'UNPRESSED' && thisTrigger.setState('PRESSED');
                //                 thisTrigger.setAriaStates({'expanded':'true'});
                //             }
                //         };
                //         changeButtonState[change] && changeButtonState[change]();
                //     }
                // );
                thisCalendar.getPublisher().subscribe(
                    function callOnCalendarMonthSelect (change, data) {
                        if (change != 'select') {
                            return;
                        }
                        proxy.update( data.year, data.month);
                        thisTrigger.getButton().trigger('click');
                    }
                );
                thisTrigger.getPublisher().subscribe(
                    function callOnTriggerStateChange (state) {
                        var toggleCalendar = {
                            'PRESSED': function() {
                                var proxyValue = proxy.getValue();
                                thisCalendar.setAlignment(
                                    $(thisTrigger.element()).offset().left,
                                    thisCalendar.element().width());
                                thisCalendar.open(proxyValue.year, proxyValue.month);
                            },
                            'UNPRESSED': function() {
                                thisCalendar.close();
                            }
                        };
                        toggleCalendar[state] && toggleCalendar[state]();
                    }    
                );
            });
        };
    }
)(jQuery);
