/* Atenção: Este plugin é independente de jQuery. */
 
/**
 * multiselect
 * Converte um campo de entrada do tipo select com atributo multiple em um combobox customizado,
 * com 1 ou mais grupos de checkboxes e um checkbos exclusivo para selecionar todos
 *  OBS: Se não for fornecido um elemento SELECT, se ele não tiver atributo MULTIPLE o plugin
 *      não vai convertê-lo, logando um aviso na console do navegador
 * Marcação HTML:
 * - O campo SELECT deve ter um LABEL devidamente associado por DI
 * - o campo SELECT deve ser MULTIPLE
 * - o campo SELECT pode conter estruturas OPTGROUP
 *   - estruturas OPTGROUP formarão uma seção de checkboxes na janela dropdown associada ao combobox
 * - o estado do widget é armazenado no SELECT original, que fica escondido, mas será submetido com o
 *   formulário
 *
 * Inicialização:
 * window.PORTALCD.WIDGETS.multiselect('my-selector')
 * 
 * Destruição:
 * window.PORTALCD.WIDGETS.multiselect('my-selector', 'destroy')
 *
 * Atributos especiais (opcional):
 * data-placeholder: informar quando quiser substituir o placeholder padrão do widget
    Ex.: <select multiple id="meuId" data-placeholder="Por favor selecione um ou mais">...
 * OBS: 
    (1) O placehoder padrão está em defaults.placeholder
    (2) Use preferencialmente com a estrutura CSS de apoio definida em 
        global/sass/includes/utils/skeleton.scss  
*/

(function adicionarWidgetMultiSelect(portalcdLib) {

    const BEMPrefix = 'multiselect';
    const BEM = {
        widget: BEMPrefix,
        a11yLog: BEMPrefix + '__a11y-log',
        clearButton: BEMPrefix + '__clear-button',
        dropdownView: BEMPrefix + '__dropdown-view',
        entryArea: BEMPrefix + '__entry-area',
        inputField: BEMPrefix + '__input-field',
        optionControl: BEMPrefix + '__option-control',
        optionsItem: BEMPrefix + '__options-item',
        optionLabel: BEMPrefix + '__option-label',
        optionText: BEMPrefix + '__option-text',
        optionsView: BEMPrefix + '__options-view',
        removeText: BEMPrefix + '__remove-text',
        section: BEMPrefix + '_section',
        selectAllButton: BEMPrefix + '__select-all-button',
        selectAllContainer: BEMPrefix + '__select-all-container',
        selectedItem: BEMPrefix + '__selected-item',
        selectedList: BEMPrefix + '__selected-list',
        sectionTitle: BEMPrefix + '__section-title',
        state: BEMPrefix + '__state',
        tag: BEMPrefix + '__tag',
        toggleDropdownButton: BEMPrefix + '__toggle-button',
        withSectionsModifier: BEMPrefix + '--with-sections'
    };
    const defaults = {
        placeholder: 'Selecione uma ou mais opções'
    };
    const widgetOptions = {};

    const setAttrs = function (element, ...attributeArray) {
        if (!element) {
            return;
        }
        attributeArray.forEach( (attr) => {
            const key = Object.keys(attr)[0];
            if (!attr[key]) {
                element.removeAttribute(key);
                return;
            }
            element.setAttribute(key,attr[key]);
        });

    };
    // Util functions
    const getRandomId = function (prefix) {
        prefix = prefix || 'id';
        return `${prefix}_${parseInt(Math.random() * 1000000)}`;
    };
    const hasBegunWith = function (part, sentence) {
        part = part.toUpperCase();
        sentence = sentence.toUpperCase();
        return (part != '' && sentence.indexOf(part) === 0);
    };
    const classify = function (className) {
        return '.' + className;
    };
    const getAncestorByClassName = function (element, className) {
        const result = element.parentElement;

        if (!result) {
            return null;
        }
        if (result.classList.contains(className)) {
            return result;
        }
        return getAncestorByClassName(result, className);

    };

    // Custom Element Functions
    const isValidMarkup = function (candidate) {
        if (candidate.tagName.toUpperCase() != 'SELECT') {
            console.warn(`multiselect: ${candidate.id} não é select!`);
            return false;
        }
        if (!candidate.hasAttribute('multiple')) {
            console.warn(`multiselect: ${candidate.id} não é de seleção múltipla!`);
            return false;
        }
        if (candidate.classList.contains(BEM.state)) {
            console.warn(`multiselect: ${candidate.id} já está ativado!`);
            return false;
        }
        return true;
    };
    const hideOriginal = function (element) {
        element.style.display = 'none';
        setAttrs(element, {'aria-hidden': true});
    };
    const createCustomCombobox = function (original) {

        const idMultiselect = getRandomId('id_wg_');
        const idDropdownView = getRandomId('id_dv_');
        const idOptionsView = getRandomId('id_ov_');
        const idInputField = getRandomId('id_if_');
        const idTagList = getRandomId('id_tl_');
        const idListDescription = getRandomId('id_ld_');
        
        const labelOriginal = document.querySelector(`label[for="${original.id}"]`);
        if (labelOriginal) {
            labelOriginal.id = getRandomId('id_lo_');
            labelOriginal.dataset.originalInput = labelOriginal.getAttribute('for');
            setAttrs(labelOriginal,{'for': idInputField});
        }

        // Criar uma combobox
        const customMultiSelect = document.createElement('div');
        customMultiSelect.classList.add(BEM.widget);
        customMultiSelect.id = idMultiselect;
        setAttrs(customMultiSelect, 
            { 'role': 'combobox'},
            {'aria-haspopup': 'listbox'},
            {'aria-expanded': 'false'},
            {'aria-owns': idDropdownView} 
        );

        // Criar uma lista para mostrar os elementos selecionados
        const tagList = document.createElement('ul');
        tagList.id = idTagList;
        tagList.classList.add(BEM.selectedList);
        setAttrs(tagList,
            {'aria-live': 'assertive'},
            {'aria-atomic': 'false'},
            {'aria-relevant': 'additions removals'},
            {'aria-describedby': `${labelOriginal.id} ${idListDescription}`}
        );

        const listDescription = document.createElement('div');
        listDescription.id = idListDescription;
        listDescription.classList.add('d-none');
        listDescription.appendChild(document.createTextNode('itens selecionados'));
        customMultiSelect.appendChild(listDescription);

        // texto para usar como 'describedby' nos botões das tags
        const removeText = document.createElement('div');
        removeText.id = getRandomId('id_rt_');
        removeText.classList.add(BEM.removeText);
        removeText.classList.add('d-none');
        removeText.appendChild(document.createTextNode('remover'));
        customMultiSelect.appendChild(removeText);

        // Criar o campo de entrada - com autompletar - do combobox
        const inputField = document.createElement('input');
        inputField.id = idInputField;
        inputField.classList.add(BEM.inputField);
        setAttrs(inputField, 
            {'type': 'text'},
            {'aria-activedescendant': ''},
            {'aria-autocomplete': 'list'} 
        );

        const entryArea = document.createElement('div');
        entryArea.classList.add(BEM.entryArea);
        entryArea.appendChild(tagList);
        entryArea.appendChild(inputField);

        // Criar o botão que limpa a lista de elementos selecionados
        // const clearButton = document.createElement('button');
        // clearButton.classList.add(BEM.clearButton);
        // setAttrs(clearButton, {'aria-controls': idTagList});
        // clearButton.insertAdjacentHTML(
        //     'afterbegin',
        //     '<span>Limpar<span class="sr-only"> a lista de selecionados</span></span>'
        // );

        // Criar o botão que controla a visibilidade da lista de opções da combobox
        const toggleDropdownButton = document.createElement('button');
        const toggleText = document.createElement('span');

        toggleText.classList.add('sr-only');
        toggleText.appendChild(document.createTextNode('abre ou fecha a lista de opções'));

        toggleDropdownButton.classList.add(BEM.toggleDropdownButton);
        setAttrs(toggleDropdownButton,
            {'aria-haspopup': 'listbox'},
            {'aria-expanded': 'false'},
            {'aria-controls': idDropdownView},
            {'tabindex': '-1'});

        toggleDropdownButton.appendChild(toggleText);

        // Criar a dropdown exibida sob comando do usuário
        const dropdownView = document.createElement('div');
        dropdownView.id = idDropdownView;
        dropdownView.classList.add(BEM.dropdownView);
        dropdownView.classList.add('is-hidden');

        // Criar o botão (checkbox) que seleciona ou desseleciona todas as opções
        const selectAllCheck = document.createElement('input');
        setAttrs(selectAllCheck,
            {'type': 'checkbox'},
            {'tabindex': -1}
        );

        const selectAllText = document.createElement('span');
        selectAllText.appendChild(document.createTextNode('Todos'));

        const selectAllButton = document.createElement('label');
        selectAllButton.id = getRandomId('id_sab_');
        selectAllButton.classList.add(BEM.selectAllButton);
        setAttrs(selectAllButton,
            {'role': 'button'},
            {'aria-pressed': 'false'},
            {'aria-controls': idOptionsView}
        );
        selectAllButton.appendChild(selectAllCheck);
        selectAllButton.appendChild(selectAllText);

        const selectAllContainer = document.createElement('div');
        selectAllContainer.classList.add(BEM.selectAllContainer);
        selectAllContainer.appendChild(selectAllButton);

        // Criar o elemento que exibe a lista de opções da combobox - completa ou filtrada
        const optionsView = document.createElement('ul');
        optionsView.id = idOptionsView;
        optionsView.classList.add(BEM.optionsView);
        setAttrs(optionsView,
            {'role': 'listbox'},
            {'aria-multiselectable': 'true'}
        );

        dropdownView.appendChild(selectAllContainer);
        dropdownView.appendChild(optionsView);

        // Montar a estrutura final da combobox
        customMultiSelect.appendChild(entryArea);
        //customMultiSelect.appendChild(clearButton);
        customMultiSelect.appendChild(toggleDropdownButton);
        customMultiSelect.appendChild(dropdownView);

        // Posicionar a estrutura no DOM e incorporar o original para gerenciar o estado
        original.parentNode.insertBefore(customMultiSelect, original);
        original.parentNode.removeChild(original);
        customMultiSelect.appendChild(original);
        original.classList.add(BEM.state);

        // Criar, se já não existir, a área para log acessível
        let log = document.querySelector(classify(BEM.a11yLog));
        if (!log) {
            log = document.createElement('div');
            log.classList.add(BEM.a11yLog);
            log.classList.add('sr-only');
            setAttrs(log,
                {'role': 'log'},
                {'aria-live': 'assertive'}
            );

            document.body.appendChild(log);
        }

        return customMultiSelect;

    };

    // Widget behavior functions
    const getWidgetPlaceholder = function (widget) {
        return widgetOptions.placeholder[widget.id];
    };
    const setWidgetPlaceholder = function (widget, placeholder) {
        widgetOptions.placeholder = widgetOptions.placeholder || {};
        widgetOptions.placeholder[widget.id] = placeholder || defaults.placeholder;

        setAttrs(widget.querySelector(classify(BEM.inputField)),
            {'placeholder':widgetOptions.placeholder[widget.id]});
    };

    const createOptionsViewSection = function (optGroup) {
        const label = optGroup.getAttribute('label');
        const fieldset = document.createElement('fieldset');
        const legend = document.createElement('legend');

        legend.classList.add(BEM.sectionTitle);
        legend.appendChild(document.createTextNode(`${label}`));

        fieldset.classList.add(BEM.section);
        fieldset.appendChild(legend);

        return fieldset;
    };
    const createOptionStructure = function (option) {
        const value = option.getAttribute('value');
        const structure = document.createElement('li');
        structure.id = getRandomId('id_st_');
        structure.classList.add(BEM.optionsItem);
        setAttrs(structure, {'role': 'option'});

        const checkbox = document.createElement('input');
        checkbox.classList.add(BEM.optionControl);
        setAttrs(checkbox,
            {'type': 'checkbox'},
            {'value': value},
            {'tabindex': -1}
        );
        if (option.selected) {
            checkbox.checked = true;
            setAttrs(structure, {'aria-selected': 'true'});
        }

        const text = document.createElement('span');
        text.classList.add(BEM.optionText);
        text.appendChild(document.createTextNode(`${option.innerText}`));

        const label = document.createElement('label');
        label.classList.add(BEM.optionLabel);
        label.appendChild(checkbox);
        label.appendChild(text);

        structure.appendChild(label);
        return structure;
    };
    const clearAllOptions = function (widget) {
        const viewOptions = widget.querySelectorAll(classify(BEM.optionControl));

        [...viewOptions].forEach( (viewOption) => {
            clearOption(widget, getOriginal(widget,viewOption.value));
        });
    };
    const clearOption = function (widget, originalOption) {
        if (!originalOption) {
            return;
        }
        const viewOptionControl = getViewOptionControl(widget, originalOption.value);
        const viewOptionItem = getViewOptionItem(viewOptionControl);
        const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));
        const selectAllButtonCheckbox = selectAllButton.querySelector('[type=checkbox]');

        removeTag(widget, originalOption);
        originalOption.selected = false;
        if (viewOptionControl) {
            viewOptionControl.checked = false;
        } 
        setAttrs(viewOptionItem, {'aria-selected': null});

        if (selectAllButtonCheckbox) {
            removeTag(widget, {
                value: 'all'
            });
            const optionControls = widget.querySelectorAll(`${classify(BEM.optionControl)}:checked`);
            [...optionControls].forEach( (control) => {
                const originalOption = getOriginal(widget,control.value);
                createTag(widget, originalOption); 
            });
        }
        selectAllButtonCheckbox.checked = false;
        setAttrs(selectAllButton, {'aria-pressed': false});

    };
    const selectOption = function (widget, originalOption, useTag = true) {
        const viewOptionControl = getViewOptionControl(widget, originalOption.value);
        const viewOptionItem = getViewOptionItem(viewOptionControl);

        if (useTag) {
            createTag(widget, originalOption);
        }
        originalOption.selected = true;
        viewOptionControl.checked = true;
        setAttrs(viewOptionItem, {'aria-selected': true});

    };
    const openDropdownView = function (widget) {
        const dropdownView = widget.querySelector(classify(BEM.dropdownView));
        const inputField = widget.querySelector(classify(BEM.inputField));
        const toggleDropdownButton = widget.querySelector(classify(BEM.toggleDropdownButton));
        const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));

        if (inputField.value.trim() === '') {
            setCompleteView(widget);
        } else {
            setFilteredView(widget);
        }

        dropdownView.classList.remove('is-hidden');
        setAttrs(toggleDropdownButton, {'aria-expanded': 'true'});
        setAttrs(widget, {'aria-expanded': 'true'});
    };
    const closeDropdownView = function (widget) {
        const dropdownView = widget.querySelector(classify(BEM.dropdownView));
        const toggleDropdownButton = widget.querySelector(classify(BEM.toggleDropdownButton));

        dropdownView.classList.add('is-hidden');
        setAttrs(toggleDropdownButton, {'aria-expanded': 'false'});
        setAttrs(widget, {'aria-expanded': 'false'});
        updateActiveItem(widget);
    };
    const setCompleteView = function (widget) {
        // Na visão geral apresentar os títulos das coleções e os elementos organizados

        const state = widget.querySelector(classify(BEM.state));
        const optionsView = widget.querySelector(classify(BEM.optionsView));
        const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));

        selectAllButton.classList.remove('d-none');
        selectAllButton.querySelector('[type=checkbox]').checked = false;

        [...optionsView.children].forEach((child) => child.parentElement.removeChild(child));

        [...state.children].forEach((node) => {
            if (node.tagName.toUpperCase() === 'OPTGROUP') {
                const optionsSection = createOptionsViewSection(node);

                widget.classList.add(BEM.withSectionsModifier);
                optionsView.appendChild(optionsSection);
                [...node.children].forEach((option) => {
                    optionsSection.appendChild(createOptionStructure(option));
                });
                return;
            } 

            optionsView.appendChild(createOptionStructure(node));
        });
        const viewOptionsLength = widget.querySelectorAll(classify(BEM.optionControl)).length;
        const viewCheckedOptionsLength = widget.querySelectorAll(`${classify(BEM.optionControl)}:checked`).length;
        if (viewOptionsLength === viewCheckedOptionsLength) {
            selectAllButton.querySelector('[type=checkbox]').checked = true;
        }

    };
    const setFilteredView = function (widget) {
        const inputField = widget.querySelector(classify(BEM.inputField));
        const state = widget.querySelector(classify(BEM.state));
        const optionsView = widget.querySelector(classify(BEM.optionsView));
        const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));

        [...(optionsView.children)].forEach((optionsItem) => optionsItem.parentElement.removeChild(optionsItem));

        [...(state.children)].forEach((node) => {
            if (node.tagName.toUpperCase() === 'OPTGROUP') {
                [...(node.children)].forEach((option) => {
                    hasBegunWith(inputField.value, option.innerText) &&
                        optionsView.appendChild(createOptionStructure(option)); 
                });
                return;
            } 
            
            hasBegunWith(inputField.value, node.innerText) &&
                optionsView.appendChild(createOptionStructure(node)); 

        });
        selectAllButton.classList.add('d-none');
        const firstFilteredElement = widget.querySelector(BEM.optionsItem); 
        updateActiveItem(widget, firstFilteredElement);

    };
    const hasUnselectedOption = function (optionsView) {
        const selectedTotal = optionsView.querySelectorAll(`${classify(BEM.optionControl)}:checked`).length;
        return selectedTotal < optionsView.querySelectorAll(classify(BEM.optionControl)).length;
    };
    const createTag = function (widget, option) {
        const selectedList = widget.querySelector(classify(BEM.selectedList));
        if (selectedList.querySelectorAll(`[data-value="${option.value}"]`).length > 0) {
            return;
        }

        const tag = document.createElement('button');
        tag.classList.add(BEM.tag);
        setAttrs(tag,
            {'data-value': option.value},
            {'aria-describedby': widget.querySelector(classify(BEM.removeText)).id}
        );
        tag.appendChild(document.createTextNode(option.innerHTML));

        const selectedItem = document.createElement('li');
        selectedItem.classList.add(BEM.selectedItem);
        selectedItem.appendChild(tag);

        const inputField = widget.querySelector(classify(BEM.inputField));
        inputField.value = '';
        setAttrs(inputField, 
            { 'placeholder':''});
        selectedList.appendChild(selectedItem);
    };
    const removeAllTags = function (widget) {
        const allTags = [...(widget.querySelectorAll(classify(BEM.tag)))];

        allTags.forEach( (tag) => {
            removeTag(widget, getOriginal(widget,tag.getAttribute('data-value')));
        });
    };
    const removeTag = function (widget, originalOption) {
        const tag = getTag(widget, originalOption.value);

        if (!tag) {
            return;
        }
        const selectedList = widget.querySelector(classify(BEM.selectedList));
        const selectedItem = tag.parentElement;
        selectedList.removeChild(selectedItem);

        const inputField = widget.querySelector(classify(BEM.inputField));
        inputField.value = '';
        if (selectedList.children.length === 0) {
            setAttrs(inputField, 
                { 'placeholder': getWidgetPlaceholder(widget)}); 
        }
    };
    const updateActiveItem = function (widget, activeItem, id) {
        const inputField = widget.querySelector(classify(BEM.inputField));
        const currentActiveItem = widget.querySelector('.is-active');
        if (currentActiveItem) {
            currentActiveItem.classList.remove('is-active');
        }

        if (!activeItem) {
            setAttrs(inputField, {'aria-activedescendant': ''});
            return;
        }
        id = id || activeItem.id;
        setAttrs(inputField, {'aria-activedescendant': id});
        activeItem.classList.add('is-active');
        activeItem.scrollIntoView(false);

    };
    const logA11yMessage = function (message) {
        const log = document.querySelector(classify(BEM.a11yLog));

        log.insertAdjacentHTML(
            'beforeend',
            `<p>${message}</p>`);
        window.setTimeout(function () {
            log.innerHTML = '';
        }, 5000);

    };

    // State-management functions
    const getOriginal = function (widget, value) {
        return widget.querySelector(`${classify(BEM.state)} option[value="${value}"]`);
    };
    const getTag = function (widget, value) {
        return widget.querySelector(`${classify(BEM.tag)}[data-value="${value}"]`);
    };
    const getViewOptionControl = function (widget, value) {
        return widget.querySelector(`${classify(BEM.optionControl)}[value="${value}"]`);
    };
    const getViewOptionItem = function (control) {
        return getAncestorByClassName(control, BEM.optionsItem);
    };

    // Listener registration functions
    const addButtonListener = function (button, listener) {

        if (button.length === 0) return;
        button = button[0];
        button.addEventListener('click', evt => {
            evt.preventDefault();
            listener(evt);
        });
        button.addEventListener('keypress', evt => {
            evt.preventDefault();
            if (evt.type === 'click' || evt.key.toUpperCase() === 'ENTER') {
                listener(evt);
            }
        });

    };
    const addToggleDropdownButtonListener = function (widget) {
        const toggleListbox = function (evt) {

            const dropdownView = widget.querySelector(classify(BEM.dropdownView));
            if (dropdownView.classList.contains('is-hidden')) {
                openDropdownView(widget);
            } else {
                closeDropdownView(widget);
            }
        };

        const toggleDropdownButton = widget.querySelectorAll(classify(BEM.toggleDropdownButton));
        addButtonListener(toggleDropdownButton, toggleListbox);
    };
    const addClearSelectedButtonListener = function (widget) {
        const clearSelectedList = function (evt) {
            const inputField = widget.querySelector(classify(BEM.inputField));

            clearAllOptions(widget);
            inputField.value = '';
        };

        const clearButton = widget.querySelectorAll(classify(BEM.clearButton));
        addButtonListener(clearButton, clearSelectedList);
    };
    const addClearTagButtonListener = function (widget) {
        const clearTag = function (evt) {
            if (evt.target.classList.contains(BEM.selectedList)) {
                widget.querySelector(classify(BEM.inputField)).focus();
                return;
            }
            const tag = evt.target;
            const tagValue = tag.getAttribute('data-value');

            if (tagValue === 'all') {
                clearAllOptions(widget);
                return;
            }

            const originalOption = getOriginal(widget, tagValue);
            clearOption(widget, originalOption); 
        };

        const selectedList = widget.querySelectorAll(classify(BEM.selectedList));
        addButtonListener(selectedList, clearTag);
    };
    const addSelectAllButtonListener = function (widget) {
        const manageOptionsViewState = function (evt) {
            const selectAllButton = evt.currentTarget;
            const optionControls = widget.querySelectorAll(classify(BEM.optionControl));

            const isChecked = selectAllButton.querySelector('[type=checkbox]').checked;
            const selectAll = {
                true: () => {
                    [...optionControls].forEach((control) => {
                        const originalValue = getOriginal(widget, control.value);
                        selectOption(widget, originalValue, false);
                    });
                    removeAllTags(widget);
                    createTag(widget, {
                        value: 'all',
                        innerHTML: 'Todos'
                    });
                },
                false: () => {
                    clearAllOptions(widget);
                    removeTag(widget, {
                        value: 'all'
                    });
                }
            };
            selectAll[isChecked]();
            selectAllButton.setAttribute('aria-pressed', isChecked);
        };

        const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));
        selectAllButton.addEventListener('change', manageOptionsViewState); // deve aplicar-se ao container
    };
    const addInputFieldEntryListener = function (widget) {
        const filterListbox = function (evt) {
            const dropdownView = widget.querySelector(classify(BEM.dropdownView));
            const inputField = widget.querySelector(classify(BEM.inputField));

            if (dropdownView.classList.contains('is-hidden')) {
                dropdownView.classList.remove('is-hidden');
            }
            if (inputField.value.trim() === '') {
                setCompleteView(widget);
                const selectAllButton = dropdownView.querySelector(classify(BEM.selectAllButton));
                updateActiveItem(widget, selectAllButton.parentElement,selectAllButton.id);
                return;
            } 

            setFilteredView(widget);
            const firstOptionsItem = dropdownView.querySelector(classify(BEM.optionsItem));
            if (firstOptionsItem) {
                updateActiveItem(widget, firstOptionsItem);
            }
            
        };

        const inputField = widget.querySelectorAll(classify(BEM.inputField))[0];
        inputField.addEventListener('input', filterListbox);
    };
    const addOptionsViewNavigationListener = function (widget) {
        const navigateOptionsView = function (evt) {
            const pressedKey = evt.key.toUpperCase();

            const takeAction = {
                'ARROWUP': () => {
                    const dropdownView = widget.querySelector(`${classify(BEM.optionControl)}:not(.is-hidden)`);
                    if (!dropdownView) {
                        return;
                    }

                    const activeDescendant = widget.querySelector(`${classify(BEM.dropdownView)} .is-active`);
                    if (!activeDescendant || !activeDescendant.classList.contains(BEM.optionsItem)) {
                        return;
                    }

                    const prevOption = activeDescendant.previousElementSibling;
                    if (prevOption && prevOption.classList.contains(BEM.optionsItem)) {
                        updateActiveItem(widget, prevOption);
                        return;
                    }

                    const prevSection = activeDescendant.parentElement.previousElementSibling;
                    if (prevSection && prevSection.classList.contains(BEM.section) &&
                        prevSection.lastChild) {
                        updateActiveItem(widget, prevSection.lastChild);
                        return;
                    }
                    const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));
                    if (selectAllButton && !selectAllButton.classList.contains('d-none')) {
                        updateActiveItem(
                            widget, 
                            selectAllButton.parentElement, 
                            selectAllButton.id);
                    }

                },
                'ARROWDOWN': () => {
                    const dropdownView = widget.querySelector(classify(BEM.dropdownView));
                    if (dropdownView.classList.contains('is-hidden')) {
                        openDropdownView(widget);

                        const selectAllButton = widget.querySelector(`${classify(BEM.selectAllButton)}:not(.d-none)`);
                        if (selectAllButton) {
                            updateActiveItem(widget, selectAllButton.parentElement, selectAllButton.id);
                            return;    
                        }
                        const firstOptionsItem = widget.querySelector(classify(BEM.optionsItem));
                        updateActiveItem(widget, firstOptionsItem);
                        return;    

                    }
                    const activeDescendant = widget.querySelector(`${classify(BEM.dropdownView)} .is-active`);

                    if (activeDescendant.classList.contains(BEM.selectAllContainer)) {
                        const firstOption = widget.querySelector(classify(BEM.optionsItem));
                        updateActiveItem(widget, firstOption);
                        return;
                    }

                    const nextOption = activeDescendant.nextElementSibling;
                    if (nextOption) {
                        updateActiveItem(widget, nextOption);
                        return;
                    }

                    const nextSection = activeDescendant.parentElement.nextElementSibling;
                    if (nextSection) {
                        // buscar o primeiro filho da próxima seção
                        const firstOption = nextSection.querySelector(classify(BEM.optionsItem));
                        updateActiveItem(widget, firstOption);
                        return;
                    }

                },
                'ENTER': () => {
                    evt.preventDefault();

                    const dropdownView = widget.querySelector(classify(BEM.dropdownView));
                    if (dropdownView.classList.contains('is-hidden')) {
                        openDropdownView(widget);
                    } else {
                        const activeDescendant = widget.querySelector(`${classify(BEM.dropdownView)} .is-active`);

                        if (activeDescendant) {

                            const checkbox = activeDescendant.querySelector('[type=checkbox]');

                            //checkbox.checked = !checkbox.checked;
                            checkbox.click();
                        }
                    }

                },
                'BACKSPACE': () => {
                    if (evt.srcElement.selectionStart != 0 || evt.srcElement.selectionEnd != 0) {
                        return;
                    }

                    const selectedList = widget.querySelector(classify(BEM.selectedList));

                    const lastTag = selectedList.querySelector(`${classify(BEM.selectedItem)}:last-child`);
                    if (!lastTag) {
                        return;
                    }
                    closeDropdownView(widget);

                    const lastTagValue = lastTag.children[0].dataset.value;
                    const lastTagText = lastTag.children[0].innerText;
                    if (lastTagValue === 'all') {
                        widget.querySelector(`${classify(BEM.selectAllButton)} [type=checkbox]`).click();
                        removeTag(widget, {
                            value: 'all'
                        });
                        logA11yMessage(`${lastTagText} removido`);
                        return;
                    }

                    const originalOption = getOriginal(widget, lastTagValue);
                    clearOption(widget, originalOption);
                    logA11yMessage(`${lastTagText} removido`);
                },
                'TAB': () => {
                    closeDropdownView(widget);
                }
            };
            takeAction[pressedKey] && takeAction[pressedKey]();
        };

        const inputField = widget.querySelector(classify(BEM.inputField));
        inputField.addEventListener('keydown', navigateOptionsView);
    };
    const addToggleCheckingListener = function (widget) {
        const manageState = function (evt) {
            evt.preventDefault();
            const checkbox = evt.target;
            const originalOption = getOriginal(widget, checkbox.value);

            const isChecked = checkbox.checked;
            const manageRelatedTag = {
                true: () => {
                    selectOption(widget, originalOption);

                    const selectAllButton = widget.querySelector(classify(BEM.selectAllButton));
                    const selectAllButtonCheckbox = selectAllButton.querySelector('[type=checkbox]');
            
                    if (!selectAllButton.classList.contains('d-none') && !hasUnselectedOption(widget.querySelector(classify(BEM.optionsView)))) {
                        selectAllButtonCheckbox.click();
                        return ;
                    }
                    selectAllButtonCheckbox.checked = false;
                    setAttrs(selectAllButton, {'aria-pressed': false});
            
                },
                false: () => {
                    clearOption(widget, originalOption);
                }
            };
            manageRelatedTag[isChecked]();
        };

        const optionsView = widget.querySelector(classify(BEM.optionsView));
        optionsView.addEventListener('change', manageState); 

    };
    const addDocumentEventsListener = function () {
        const closeOpenWidgetsOverClickOutside = function (evt) {

            const clickedElement = evt.target;

            const widgets = [...document.querySelectorAll(classify(BEM.widget))];
            widgets.forEach((widget) => {
                const dropdownView = widget.querySelector(classify(BEM.dropdownView));
                const toggleDropdownButton = widget.querySelector(classify(BEM.toggleDropdownButton));
                // Se o clique for fora do botão de toggle e o dropdown correspondente estiver aberto, feche-o
                if (!dropdownView.classList.contains('is-hidden') && toggleDropdownButton != clickedElement) {
                    dropdownView.classList.add('is-hidden');
                }

            });
        };
        const closeOpenWidgetsOverEscape = function (evt) {

            if (evt.key != 'Escape') return;

            const widgets = [...document.querySelectorAll(classify(BEM.widget))];
            widgets.forEach((widget) => {
                const dropdownView = widget.querySelector(classify(BEM.dropdownView));
                dropdownView.classList.add('is-hidden');
            });
        };
        const cancelClickBubble = function (evt) {

            evt.stopPropagation();
        };

        const dropdownViews = document.querySelectorAll(classify(BEM.dropdownView));

        document.addEventListener('click', closeOpenWidgetsOverClickOutside);
        [...dropdownViews].forEach(
            (dropdownView) => dropdownView.addEventListener('click', cancelClickBubble)
        );
        document.addEventListener('keyup', evt => {
            evt.preventDefault();
            closeOpenWidgetsOverEscape(evt);
        });
    };
    const addEntryAreaEventListener = function (widget) {
        const setFocus = function (evt) {
            widget.querySelector(classify(BEM.inputField)).focus();
        };

        const entryArea = widget.querySelector(classify(BEM.entryArea));
        entryArea.addEventListener('click', setFocus); 
    };

    const destroyWidget = function (querySelector) {
        const candidates = [...document.querySelectorAll(querySelector)];

        candidates.forEach( (candidate) => {
            // find original element
            const widget = candidate.closest(classify(BEM.widget));
            // extract from structure
            if (!widget) {
                return;
            }
            const original = candidate;
            original.classList.remove(BEM.state);
            original.style.display = '';
            original.parentElement.removeChild(original);

            const originalLabel = document.querySelector(`label[data-original-input="${original.id}"]`);
            setAttrs(originalLabel, {'for': original.id});
            originalLabel.removeAttribute('data-original-input');
            // put it before structure
            widget.parentElement.insertBefore(original, widget);

            // eliminate the structure
            widget.parentElement.removeChild(widget);
        });

    };
    const buildMultiSelectWidget = function (querySelector, destroy = '') {

        
        if (destroy === 'destroy') {
            destroyWidget(querySelector);
            return;
        }
        const validElements = [...(document.querySelectorAll(querySelector))]
            .filter(el => isValidMarkup(el));

        validElements.forEach(element => {
            hideOriginal(element);

            const customMultiSelect = createCustomCombobox(element);
            addToggleDropdownButtonListener(customMultiSelect);
            //addClearSelectedButtonListener(customMultiSelect);
            addClearTagButtonListener(customMultiSelect);
            addInputFieldEntryListener(customMultiSelect);
            addToggleCheckingListener(customMultiSelect);
            addSelectAllButtonListener(customMultiSelect);
            addEntryAreaEventListener(customMultiSelect);
            addOptionsViewNavigationListener(customMultiSelect);

            const placeholder = customMultiSelect.querySelector(classify(BEM.state))
                .getAttribute('data-placeholder');
            setWidgetPlaceholder(customMultiSelect, placeholder);
        });

        addDocumentEventsListener();

    };

    portalcdLib.WIDGETS = portalcdLib.WIDGETS || {};
    portalcdLib.WIDGETS.multiselect = buildMultiSelectWidget;

}) (window.PORTALCD = window.PORTALCD || {});