/* --
    Arquivo CSS correspondente em global/sass/includes/components/info-revealer.scss
-- */
$(document).ready(function () {
    /*
        Alterna texto do botão do revelador de informação
        - Componente global .g-info-revealer
    */
    $('.js-info-reveal-button-change-text').click(function () {
        var texto = $(this).find('.g-info-revealer__toggle-button-text');

        if (texto.text() == 'Abrir') {
            texto.text('Fechar');
        } else {
            texto.text('Abrir');
        }
    });
});