/**
 * Plugin para Controlar a ativação de caixa de texto com efeito fadeout a partir da quarta linha
 * Funcionamento: Ativado no script local da página, calcula a altura da linha, controla a altura da caixa 
 * e não deixa que a quinta linha seja mostrada.
 * 
 * Transformação do DOM:
 *  1 - atribui a classe 'last-line-fadable' ao elemento que o chama
 *  2 - atribui/elimina a classe 'active', conforme altura da caixa
 * 
 *  Obs: Trabalha em conjunto com as definições do arquivo SASS global 'components/_fadedBox.scss'
 *       ... e definições CSS locais da página (consulte aquele SASS global para detalhes)
 */
(function ($) {
    $.fn.fadeLastLine = function (options) {

        var defaults = {
            lastLineDesktop: 4,
            lastLineMobile: 4,
            lastLine: 4
        };

        options = $.extend(defaults, options);

        // From https://stackoverflow.com/questions/4392868/javascript-find-divs-line-height-not-css-property-but-actual-line-height
        function getLineHeight(el) {
            var temp = document.createElement(el.nodeName),
                ret;
            temp.setAttribute('style', 'margin:0; padding:0; ' +
                'font-family:' + (el.style.fontFamily || 'inherit') + '; ' +
                'font-size:' + (el.style.fontSize || 'inherit'));
            temp.innerHTML = 'A';

            el.parentNode.appendChild(temp);
            ret = temp.clientHeight;
            temp.parentNode.removeChild(temp);

            return ret;
        }

        function controlarAtivacao(thisElement, maxHeight) {
            thisElement.removeClass('active');
            thisElement.css('max-height', 'none');
        
//            console.log('Máximo: ' + maxHeight + ';tamanho: ' + thisElement.height());
            if (maxHeight < thisElement.height() * 0.97) { // Ajusta a altura calculada do elemento em 3% para compatibilidade entre principais browsers
                thisElement.addClass('active');
                thisElement.css('max-height', maxHeight+'px');
            }
        }

        return this.each(function () {
            let maxHeight,
                lineHeight,
                thisElement;

            $(this).addClass('last-line-fadable');
            lineHeight = getLineHeight(this);
            maxHeight = lineHeight * options.lastLine;

            thisElement = $(this);
            controlarAtivacao(thisElement, maxHeight);

            // controla a altura do elemento no resize
            $(window).resize( function() {
                controlarAtivacao(thisElement, maxHeight);
            });
        });
    };
})(jQuery);