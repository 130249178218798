(function ($) {

    $.fn.articleReadMore = function () {

        return this.each(function () {

            var fadeOutArea = $(this).find('.js-article-read-more__content'),
                fadeoutButton;

            // Acrescenta as classes para efeito de limite de altura e fade-out
            fadeOutArea.addClass('u-fadeout-text-bottom u-altura-limitada');
            // Cria o botão...
            fadeoutButton = $('<button class="button button--simple button--sm button--normal-case artigo__leia-mais-botao" aria-label="Leia a matéria completa - Revelar o conteúdo da matéria em dispositivos visuais">Leia a matéria completa</button>');
            // ... e o adiciona no lugar marcado
            fadeoutButton.appendTo($(this).find('.js-article-read-more__trigger-wrapper'));

            fadeoutButton.one('click', function () {
                // Remove as classes do conteúdo
                fadeOutArea.removeClass('u-fadeout-text-bottom u-altura-limitada');
                // Remove o botão
                fadeoutButton.remove();
            });
        });
    };
})(jQuery);

$('.js-article-read-more').articleReadMore();
