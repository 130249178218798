/* --- ---------------------------------------------------
    Abas com dropdown na resolução mobile
---------------------------------------------------------- */
/* Testa se uma regra css foi alterada por media query */
$(document).ready(function () {
    // Roda o teste no carregamento inicial da página
    checkSizeTab();

    // Roda o teste no redimensionamento da janela
    $(window).resize(checkSizeTab);
});

var btnCollapse = $('.tab-dropdown__choose');

function checkSizeTab() {
    if ($(btnCollapse).css('display') == 'none') {
        $('.tab-dropdown__nav').removeClass('collapse');
    } else {
        $('.tab-dropdown__nav').addClass('collapse');
    }
}

/* $('.tab-dropdown__nav-item').on('click', function (event) {
    var botao = $(this).closest('#tab-dropdown__nav').prev('.tab-dropdown__choose');

    botao.trigger('click');
    botao.find('.tab-dropdown__texto').text(event.target.text);
}); */

$('.tab-dropdown__nav-item').on('click', function (event) {
    var btnCollapse = $(this).closest('.tab-dropdown__nav').siblings('.tab-dropdown__choose');
    $('.tab-dropdown__nav').removeClass('show');
    btnCollapse.addClass('collapsed');

    btnCollapse.text(event.target.text);
    btnCollapse.append('<span class="tab-dropdown__icon" aria-hidden="true"></span>');
});