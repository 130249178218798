
(function ($) {

    // Valores default para o plugin
    var defaults = {
        dateFormat: 'mm/dd/yy',
        keepFocus: true,
        beforeShowDay: $.datepicker.noWeekends
    };

    // Funções privadas
    var onSelectDefault = function (diaEscolhido) {
        // console.log('Selecionou o dia ' + diaEscolhido + ' para exibir');
    };
    var beforeShowDayDefault = function (dia) {
        return [ $.datepicker.noWeekends(dia)[0], '' ];
    };


    // Configuração default do jquery-ui-datepicker
    $.datepicker.regional['pt-BR'] = {
		closeText: 'Fechar',
		prevText: 'Anterior',
		nextText: 'Próximo',
		currentText: 'Hoje',
		monthNames: ['Janeiro','Fevereiro','Março','Abril','Maio','Junho',
		'Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
		monthNamesShort: ['Jan','Fev','Mar','Abr','Mai','Jun',
		'Jul','Ago','Set','Out','Nov','Dez'],
		dayNames: ['Domingo','Segunda-feira','Terça-feira','Quarta-feira','Quinta-feira','Sexta-feira','Sábado'],
		dayNamesShort: ['Dom','Seg','Ter','Qua','Qui','Sex','Sáb'],
		dayNamesMin: ['D','S','T','Q','Q','S','S'],
		weekHeader: 'Sm',
		dateFormat: 'dd/mm/yy',
		firstDay: 0,
		isRTL: false,
		showMonthAfterYear: false,
        yearSuffix: ''};
    $.datepicker.setDefaults( $.datepicker.regional['pt-BR'] );

    // Funções privadas estáticas

    $.fn.inlineCalendar = function (options, onSelectCallback, beforeShowDayCallback ) {

        return this.each(function () {

            var elemento = $(this);
            // Controle de Estado
            options = $.extend( defaults, options, {});

            // Funções Privadas
            options.beforeShowDay = beforeShowDayCallback || beforeShowDayDefault;
            options.onSelect = onSelectCallback || onSelectDefault;

            // Inicialização
            elemento.wrap('<div class="g-calendar-container"></div>');
            elemento.datepicker(options);

            // Escutadores de eventos
            if (!options.keepFocus) {
                elemento.find('a').attr('tabindex', '-1');
            }

        });
    };
})(jQuery);